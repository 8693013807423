<pgcard TimeOut="3000" AdditionalClasses="widget-11 card no-border  no-margin widget-loader-bar full-height" ProgressType="bar" Maximize="false" Toggle="false" Close="false" BodyClass="no-padding">
	<ng-template #CardTitle>
		<span class="font-montserrat fs-11 all-caps">
			Today's Table
		</span>
	</ng-template>

	<div class="p-l-20 p-r-20 p-b-10 p-t-5">
		<div class="pull-left">
			<h3 class="text-primary no-margin">Pages</h3>
		</div>
		<h4 class="pull-right semi-bold no-margin"><sup>
			<small class="semi-bold">$</small>
		</sup> 102,967
		</h4>
		<div class="clearfix"></div>
	</div>
	<div class="widget-11-table auto-overflow">
		<table class="table table-condensed table-hover">
			<tbody>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">dewdrops</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18">$27</span>
				</td>
			</tr>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">johnsmith</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18 text-primary">$1000</span>
				</td>
			</tr>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">janedrooler</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18">$27</span>
				</td>
			</tr>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">johnsmith</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18 text-primary">$1000</span>
				</td>
			</tr>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">dewdrops</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18">$27</span>
				</td>
			</tr>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">johnsmith</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18 text-primary">$1000</span>
				</td>
			</tr>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">dewdrops</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18">$27</span>
				</td>
			</tr>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">johnsmith</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18 text-primary">$1000</span>
				</td>
			</tr>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">dewdrops</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18">$27</span>
				</td>
			</tr>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">johnsmith</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18 text-primary">$1000</span>
				</td>
			</tr>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">johnsmith</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18 text-primary">$1000</span>
				</td>
			</tr>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">dewdrops</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18">$27</span>
				</td>
			</tr>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">johnsmith</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18 text-primary">$1000</span>
				</td>
			</tr>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">dewdrops</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18">$27</span>
				</td>
			</tr>
			<tr>
				<td class=" fs-12">Purchase CODE #2345</td>
				<td class="text-right">
					<span class="hint-text small">johnsmith</span>
				</td>
				<td class="text-right b-r b-dashed b-grey">
					<span class="hint-text small">Qty 1</span>
				</td>
				<td>
					<span class="font-montserrat fs-18 text-primary">$1000</span>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
	<div class="p-t-15 p-b-15 p-l-20 p-r-20">
		<p class="small no-margin">
			<a href="#" class="btn-circle-arrow b-grey"><i class="pg-icon">chevron_down</i></a>
			<span class="hint-text ">Show more details of <a href="#"> Revox pvt ltd </a></span>
		</p>
	</div>
</pgcard>