    <!-- START CONTAINER FLUID -->
    <pg-container>
      <pg-breadcrumb>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
          <li class="breadcrumb-item active">Cards</li>
        </ol>
      </pg-breadcrumb> 
     <!-- END BREADCRUMB --> 
     <!-- START card -->
     <div class="card card-transparent">
       <div class="card-header  ">
         <div class="card-title">Pages Cards
         </div>
       </div>
       <div class="m-0 row card-body">
         <div class="col-lg-4 no-padding">
           <div class="p-r-30">
             <h3>Effortless Customization</h3>
             <br>
             <p>Cards are pluggable UI components that are managed and displayed in a web portal. Cards in Pages are created by reusing the <a href="http://getbootstrap.com/components/#cards">cards</a> introduced in Bootstrap to enable effortless customization.
             </p>
           </div>
         </div>
         <div class="col-lg-8 sm-no-padding">
           <pgcard (onRefresh)="sampleRefresh()" [Loading]="isLoading" [ShowMessage]="errorMessage" [Message]="message">
             <ng-template #CardTitle>Sample</ng-template>
             <ng-template #CardExtraControls>                
                <li>
                  <div class="dropdown" dropdown>
                      <a href="javascript:void(0);" dropdownToggle role="button" aria-expanded="false">
                      <i class="card-icon card-icon-settings "></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu  role="menu" aria-labelledby="card-settings">
                      <a href="javascript:void(0);" class="dropdown-item">API</a>
                      <a href="javascript:void(0);" class="dropdown-item">Preferences</a>
                      <a href="javascript:void(0);" class="dropdown-item">About</a>
                      </div>
                  </div>
                </li>
              </ng-template>
              <h3>
              <span class="semi-bold">Advance</span> Tools</h3>
                <p>We have crafted Pages Cards to suit any use case. Add a maximize button <i class="pg pg-fullscreen"></i> into your Cards controls bar to make the Cards go full-screen. This will come handy if you want to show lot of content inside a Cards and want to give the content some room to breath</p>
                <br>
                <div>
                  <div class="profile-img-wrapper m-t-5 inline">
                    <img width="35" height="35" src2x="assets/img/profiles/avatar_small2x.jpg" pgRetina src1x="assets/img/profiles/avatar_small.jpg" alt="" src="assets/img/profiles/avatar_small2x.jpg">
                    <div class="chat-status available">
                    </div>
                  </div>
                  <div class="inline m-l-10">
                    <p class="small hint-text m-t-5">VIA senior product manage
                      <br>for UI/UX at REVOX</p>
                  </div>
                </div>             
           </pgcard>
         </div>
       </div>
     </div>
     <!-- END card -->
    </pg-container>
   <!-- END CONTAINER FLUID -->
   <!-- START CONTAINER FLUID -->
   <pg-container>
     <div class="row">
       <div class="col-lg-12">
         <div class="card card-transparent">
           <div class="card-header ">
             <div class="card-title">Portlet Tools
             </div>
           </div>
           <div class="card-body">
             <div class="row">
               <div class="col-lg-4">
                <pgcard Maximize="false" ProgressType="bar" TimeOut="2500">
                  <ng-template #CardTitle>Portlet Title</ng-template>
                  <h3>
                  <span class="semi-bold">Basic</span> Tools</h3>
                  <p>Basic Portlet tools include a slide toggle button <i class="card-icon card-icon-collapse"></i>, refresh button <i class="card-icon card-icon-refresh"></i> and a close button <i class="card-icon card-icon-close"></i> All these are fully customizable and come with callback functions to integrate with your code. Clicking on the refresh button will simulate an AJAX call.
                  </p>       
                </pgcard>
               </div>
               <div class="col-lg-4">
                <pgcard Maximize="false" Refresh="false" Toggle="false">
                  <ng-template #CardTitle>Portlet One</ng-template>
                  <h3>
                  <span class="semi-bold">Without</span> Scroll</h3>
                  <p>When it comes to digital design, the lines between functionality, aesthetics, and psychology are inseparably blurred. Without the constraints of the physical world, there’s no natural form to fall back on, and every bit of constraint and affordance</p>   
                </pgcard>
               </div>
               <div class="col-lg-4">
                <pgcard Maximize="false" Refresh="false" Toggle="false">
                  <ng-template #CardTitle>Portlet One</ng-template>
                    <div class="scrollable demo-card-scrollable" perfectScrollbar>

                        <h3>
                        <span class="semi-bold">With</span> Scroll</h3>
                        <p>When it comes to digital design, the lines between functionality, aesthetics, and psychology are inseparably blurred. Without the constraints of the physical world, there’s no natural form to fall back on, and every bit of constraint and affordance must be introduced intentionally. Good design makes a product useful. A product is bought to be used. It has to satisfy certain criteria, not only functional, but also psychological and aesthetic. Good design emphasizes the usefulness of a product whilst disregarding anything that could possibly detract from it. It’s always obvious when design is an afterthought. The hallmarks of the engineering-first approach are everywhere: inscrutable interfaces, convoluted workflows, user guides the size of The Iliad. This was the dominant approach for the first several decades of personal computing, and it’s left its mark in the form of software designed with its creators in mind, rather than its users.</p>

                    </div>
                  </pgcard>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </pg-container>
   <!-- END CONTAINER FLUID -->
   <!-- START CONTAINER FLUID -->
   <pg-container>
     <div class="card card-transparent">
       <div class="card-header ">
         <div class="card-title">Style Options
         </div>
       </div>
       <div class="card-body">
         <div class="row">
           <div class="col-lg-4">
            <pgcard Maximize="false" Refresh="false" Toggle="false" HeaderClass="separator">
              <ng-template #CardTitle>Portlet Three</ng-template>
              <h3>
                <span class="semi-bold">With</span> Separator</h3>
              <p>When it comes to digital design, the lines between functionality, aesthetics, and psychology are inseparably blurred. Without the constraints of the physical world, there’s no natural form to fall back on, and every bit of constraint and affordance must be introduced intentionally. Good design makes a product useful. A product is bought to be used. </p>
            </pgcard>
           </div>
           <div class="col-lg-4">
              <pgcard Controls="false" Type="transparent">
                <ng-template #CardTitle>Portlet Four</ng-template>
                <h3>
                    <span class="semi-bold">Transparent</span>
                  </h3>
                        <p>When it comes to digital design, the lines between functionality, aesthetics, and psychology are inseparably blurred. Without the constraints of the physical world, there’s no natural form to fall back on, and every bit of constraint and affordance must be introduced intentionally. Good design makes a product useful. A product is bought to be used.</p>
                </pgcard>
           </div>
           <div class="col-lg-4">
              <pgcard Maximize="false" Refresh="false" Toggle="false" HeaderClass="separator" Type="default bg-success">
                  <ng-template #CardTitle>Portlet Three</ng-template>
                  <h3>
                    <span class="semi-bold">With</span> Separator</h3>
                  <p>When it comes to digital design, the lines between functionality, aesthetics, and psychology are inseparably blurred. Without the constraints of the physical world, there’s no natural form to fall back on, and every bit of constraint and affordance must be introduced intentionally. Good design makes a product useful. A product is bought to be used. </p>
                </pgcard>
           </div>
         </div>
       </div>
     </div>
   </pg-container>
   <!-- END CONTAINER FLUID -->
   <!-- START CONTAINER FLUID -->
   <pg-container>
     <!-- START card -->
     <div class="card card-transparent">
       <div class="card-body">
         <div class="row">
           <div class="col-lg-4">
             <div class="p-r-30">
               <h3>SVG animated progress indicators</h3>
               <br>
               <p>Pages comes with two types of inderminate progress indicators: linear and circular. These have been created using animated SVGs to support various screen sizes and retina displays. We have integrated them into Cards too.
               </p>
               <div class="inline">
                 <p class="small hint-text">
                   <br> <a href="progress.html">More about progress &amp; activity indicators</a>
                 </p>
               </div>
             </div>
           </div>
           <div class="col-lg-8">
              <pgcard MinimalHeader="true" TimeOut="2500">
                <ng-template #CardTitle>Portlet Four</ng-template>
                <h3>
                <span class="semi-bold">Minimal</span> Portlet</h3>
                <p>Click on the refresh icon to simulate an AJAX call and to see an animated circular progress indicatorabove the portlet. This is the Pages default progress indicator for Cards. Don't like this style? Simply change the style by setting portlet options.
                </p>  
              </pgcard>
           </div>
         </div>
       </div>
     </div>
     <!-- END card -->
   </pg-container>
   <!-- END CONTAINER FLUID -->
   <!-- START CONTAINER FLUID -->
   <pg-container>
     <div class="card card-transparent">
       <div class="card-header ">
         <div class="card-title">Portlet Progress indicators
         </div>
       </div>
       <div class="card-body">
         <div class="row">
           <div class="col-lg-6">
              <pgcard Maximize="false" ProgressType="bar" TimeOut="2500">
                <ng-template #CardTitle>Portlet Title</ng-template>
                <h3>
                <span class="semi-bold">Linear</span> Progress</h3>
                <p>Click on the refresh icon to simulate an AJAX call and to see an animated progress bar indicator above the portlet. These progress bars come in seven different colors that are available in the Pages contextual color scheme.
                </p>
              </pgcard>
           </div>
           <div class="col-lg-6">
              <pgcard Maximize="false" ProgressType="circle" TimeOut="2500">
                <ng-template #CardTitle>Portlet Title</ng-template>
                <h3>
                    <span class="semi-bold">Circular</span> Progress</h3>
                        <p>Click on the refresh icon to simulate an AJAX call and to see an animated circular progress indicatorabove the portlet. This is the Pages default progress indicator for Cards. Don't like this style? Simply change the style by setting portlet options.
                        </p>
              </pgcard>
           </div>
         </div>
       </div>
     </div>
   </pg-container>
   <!-- END CONTAINER FLUID -->
   <!-- START CONTAINER FLUID -->
   <pg-container>
     <div class="card card-transparent">
       <div class="card-header ">
         <div class="card-title">Portlet Progress Indicator colors
         </div>
       </div>
       <div class="card-body">
         <div class="row">
           <div class="col-lg-4">
              <pgcard Maximize="false" ProgressType="bar" ProgressColor="success" TimeOut="2500">
                <ng-template #CardTitle>Portlet Title</ng-template>
                <h3>
                <span class="semi-bold">Linear</span> Progress</h3>
                <p>Click on the refresh icon to simulate an AJAX call and to see an animated progress bar indicator above the portlet. These progress bars come in seven different colors that are available in the Pages contextual color scheme.
                </p>
              </pgcard>
           </div>
           <div class="col-lg-4">
              <pgcard ProgressColor="success" Maximize="false" TimeOut="2500">
                <ng-template #CardTitle>Portlet Title</ng-template>
                <h3>
                <span class="semi-bold">Circular</span> Progress</h3>
                    <p>Click on the refresh icon to simulate an AJAX call and to see an animated circular progress indicatorabove the portlet. This is the Pages default progress indicator for Cards. Don't like this style? Simply change the style by setting portlet options.
                    </p>
              </pgcard>
           </div>
           <div class="col-lg-4">
              <pgcard (onRefresh)="errorCardSampleRefresh()" Maximize="false" [Loading]="errorCard_isLoading" [ShowMessage]="errorCard_errorMessage" [Message]="errorCard_message">
                <ng-template #CardTitle>Portlet Title</ng-template>
                <h3>
                Portlet with <span class="semi-bold">Errors</span></h3>
                <p>Anything that can go wrong will go wrong. Click on the refresh icon in the portlet controls to the top right to see how an error message can be displayed inside a portlet. Recommended to use with failed AJAX callbacks that are triggered from a portlet.
                </p>           
              </pgcard>
           </div>
         </div>
       </div>
     </div>
   </pg-container>
   <!-- END CONTAINER FLUID -->
   <!-- START CONTAINER FLUID -->
   <pg-container>
     <div class="card card-transparent">
       <div class="card-header ">
         <div class="card-title">Draggable Cards
         </div>
       </div>
       <div class="card-body">
         <div class="row">
           <div class="col-lg-6 sortable-column" ngxDroppable="example">
             <div class="card card-default bg-danger" ngxDraggable>
               <div class="card-header ">
                 <div class="card-title">Draggable Portlet
                 </div>
                 <div class="card-controls">
                   <ul>
                     <li><a href="javascript:void(0);" class="card-collapse" data-toggle="collapse"><i
                   class="card-icon card-icon-collapse"></i></a>
                     </li>
                     <li><a href="javascript:void(0);" class="card-refresh" data-toggle="refresh"><i
                   class="card-icon card-icon-refresh"></i></a>
                     </li>
                     <li><a href="javascript:void(0);" class="card-close" data-toggle="close"><i
                   class="card-icon card-icon-close"></i></a>
                     </li>
                   </ul>
                 </div>
               </div>
               <div class="card-body">
                 <h3 >
             <span class="semi-bold">Drag</span> me!</h3>
                 <p>When it comes to digital design, the lines between functionality, aesthetics, and psychology are inseparably blurred. Without the constraints of the physical world, there’s no natural form to fall back on, and every bit of constraint and affordance must be introduced intentionally. Good design makes a product useful. A product is bought to be used. It has to satisfy certain criteria, not only functional, but also psychological and aesthetic. </p>
               </div>
             </div>
             <div class="card card-default bg-complete" ngxDraggable>
               <div class="card-header ">
                 <div class="card-title">Draggable Portlet
                 </div>
                 <div class="card-controls">
                   <ul>
                     <li><a href="javascript:void(0);" class="card-collapse" data-toggle="collapse"><i
                   class="card-icon card-icon-collapse"></i></a>
                     </li>
                     <li><a href="javascript:void(0);" class="card-refresh" data-toggle="refresh"><i
                   class="card-icon card-icon-refresh"></i></a>
                     </li>
                     <li><a href="javascript:void(0);" class="card-close" data-toggle="close"><i
                   class="card-icon card-icon-close"></i></a>
                     </li>
                   </ul>
                 </div>
               </div>
               <div class="card-body">
                 <h3 >
             <span class="semi-bold">Drag</span> me!</h3>
                 <p>When it comes to digital design, the lines between functionality, aesthetics, and psychology are inseparably blurred. Without the constraints of the physical world, there’s no natural form to fall back on, and every bit of constraint and affordance must be introduced intentionally. Good design makes a product useful. A product is bought to be used. It has to satisfy certain criteria, not only functional, but also psychological and aesthetic. </p>
               </div>
             </div>
           </div>
           <div class="col-lg-6 sortable-column" ngxDroppable="example">
             <div class="card card-default bg-primary" ngxDraggable>
               <div class="card-header ">
                 <div class="card-title">Draggable Portlet
                 </div>
                 <div class="card-controls">
                   <ul>
                     <li><a href="javascript:void(0);" class="card-collapse " data-toggle="collapse"><i
                   class="card-icon card-icon-collapse"></i></a>
                     </li>
                     <li><a href="javascript:void(0);" class="card-refresh " data-toggle="refresh"><i
                   class="card-icon card-icon-refresh"></i></a>
                     </li>
                     <li><a href="javascript:void(0);" class="card-close " data-toggle="close"><i
                   class="card-icon card-icon-close"></i></a>
                     </li>
                   </ul>
                 </div>
               </div>
               <div class="card-body">
                 <h3 >
             <span class="semi-bold">Drag</span> me!</h3>
                 <p>When it comes to digital design, the lines between functionality, aesthetics, and psychology are inseparably blurred. Without the constraints of the physical world, there’s no natural form to fall back on, and every bit of constraint and affordance must be introduced intentionally. Good design makes a product useful. A product is bought to be used. It has to satisfy certain criteria, not only functional, but also psychological and aesthetic. </p>
               </div>
             </div>
             <div class="card card-default bg-success" ngxDraggable>
               <div class="card-header ">
                 <div class="card-title">Draggable Portlet
                 </div>
                 <div class="card-controls">
                   <ul>
                     <li><a href="javascript:void(0);" class="card-collapse" data-toggle="collapse"><i
                   class="card-icon card-icon-collapse"></i></a>
                     </li>
                     <li><a href="javascript:void(0);" class="card-refresh" data-toggle="refresh"><i
                   class="card-icon card-icon-refresh"></i></a>
                     </li>
                     <li><a href="javascript:void(0);" class="card-close" data-toggle="close"><i
                   class="card-icon card-icon-close"></i></a>
                     </li>
                   </ul>
                 </div>
               </div>
               <div class="card-body">
                 <h3 >
             <span class="semi-bold">Drag</span> me!</h3>
                 <p>When it comes to digital design, the lines between functionality, aesthetics, and psychology are inseparably blurred. Without the constraints of the physical world, there’s no natural form to fall back on, and every bit of constraint and affordance must be introduced intentionally. A product is bought to be used. It has to satisfy certain criteria, not only functional, but also psychological and aesthetic.
                 </p>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </pg-container>
   <!-- END CONTAINER FLUID -->
