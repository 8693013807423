<pgcard AdditionalClasses="card no-border  no-margin " HeaderClass="hidden" BodyClass="no-padding">

  <div class="card no-border  no-margin">
    <div class="padding-15">
      <div class="item-header clearfix">
        <div class="thumbnail-wrapper d32 circular">
          <img width="40" height="40" src="assets/img/profiles/3x.jpg" data-src="assets/img/profiles/3.jpg"
            data-src-retina="/assets/img/profiles/3x.jpg" alt="">
        </div>
        <div class="inline m-l-10">
          <p class="no-margin">
            <strong>Anne Simons</strong>
          </p>

          <p class="no-margin hint-text">Shared a link
            <span class="location semi-bold"><i class="pg-icon">map</i> NY center</span>
          </p>
        </div>
      </div>
    </div>
    <hr class="no-margin">
    <div class="padding-15">
      <p>Inspired by : good design is obvious, great design is transparent</p>

      <div class="hint-text">via themeforest</div>
    </div>
    <div class="relative">
      <ul class="buttons pull-top top-right list-inline p-r-10 p-t-10">
        <li>
          <a class="text-white" href="#"><i class="pg-icon">expand</i>
          </a>
        </li>
        <li>
          <a class="text-white" href="#"><i class="pg-icon">comment</i>
          </a>
        </li>
      </ul>
      <div class="widget-19-post no-overflow">
        <img src="assets/img/social-post-image.png" class="block center-margin relative" alt="Post">
      </div>
    </div>
    <div class="padding-15">
      <div class="hint-text pull-left fs-13">few seconds ago</div>
      <ul class="list-inline pull-right no-margin">
        <li><a class="text-color hint-text fs-13 d-flex" href="#"><i class="pg-icon m-r-5">comment_alt</i> 5,345</a>
        </li>
        <li><a class="text-color hint-text fs-13 d-flex" href="#"><i class="pg-icon m-r-5">heart</i> 23K</a>
        </li>
      </ul>
      <div class="clearfix"></div>
    </div>
  </div>
</pgcard>
