import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import ToolboxJson from 'assets/data/toolbox.json';
import { environment } from 'environments/environment.prod';

declare var pg: any;
@Component({
  selector: 'app-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.scss']
})
export class ToolboxComponent implements OnInit {
  toolboxJson;
  allCategories: any[] = [];

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.getSetups();
  }

  private getSetups() {

    this.http.get<any>(environment.apiUrl + '/system/setups/apps-and-tools', {
      headers: {
        Authorization: 'Token token=' + 'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJJZCI6IjEiLCJzdWIiOiJvZ3V6aGFua2lyY2FsaSIsImVtYWlsIjoib2d1emhhbmtpcmNhbGkiLCJqdGkiOiIxNDFiYzJhYi04MjVmLTQzMmEtYWUxMC0wYmU2NjI0NWEzZjkiLCJuYmYiOjE2ODAzOTIxOTQsImV4cCI6MTY4MDQxMzc5NCwiaWF0IjoxNjgwMzkyMTk0LCJpc3MiOiJtb2hhbWFkbGF3YW5kLmNvbSIsImF1ZCI6Im1vaGFtYWRsYXdhbmQuY29tIn0.fsekusyeYXx4CNdY4khpphWmeW3jz6pTlSPdae5To63c3OZBLf7gMUNtzr0K_afJV2-80QAdaZsacZB-OgEnGQ'
      }
    }).subscribe(data => {
      this.allCategories = data;
    });
  }
}
