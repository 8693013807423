<pg-container>
  <!-- <ol class="breadcrumb breadcrumb-alt">
    <li class="breadcrumb-item">
      <a href="javascript:void(0)"><a href="/">Home</a></a>
    </li>
    <li class="breadcrumb-item active"><a href="/blog">Blog</a></li>
  </ol> -->

  <div class="row">
    <div class="col-md-2"></div>

    <div class="col-md-8">
      <div class="card card-transparent">
        <div class="card-body">
          <p *ngIf="!post.title">Loading...</p>
          <div class="no-padding full-height m-t-20">
            <h2 class="m-b-20"><b>{{post.title}}</b></h2>
            <div class="d-flex justify-content-between m-b-5" *ngIf="post.title">
              <p class="label small-text hint-text">
                <!-- <i class="{{siteJson.blog.languageIconClass}}"></i> {{ post.language | uppercase }}, -->
                <i class="{{siteJson.blog.readTimeIconClass}}"></i> {{post.htmlContent | readingtime}} min
              </p>
              <p class="small-text hint-text">
                <i class="{{siteJson.blog.publishDateIconClass}}"></i>
                {{ post.publishDate | date:'medium' }}
              </p>
            </div>

            <!-- <img class="image-responsive-height m-t-20" src="assets/{{post.imagePath}}" alt="">
            <br>
            <br> -->

            <p class="no-margin" [innerHTML]="post.htmlContent"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-2"></div>
  </div>

</pg-container>