<div class="social-wrapper">
  <div class="social" data-pages="social">
    <!-- START JUMBOTRON -->
    <div class="jumbotron" data-social="cover" coverpage>
      <div
        class="cover-photo"
        pg-parallax
        [ngStyle]="{ 'background-image': 'url(assets/img/social/cover.jpg)' }"
      ></div>
      <div
        [ngClass]="{
          container: _mode == true,
          'container-fluid': _mode != true
        }"
        class="container-fixed-lg sm-p-l-0 sm-p-r-0"
      >
        <div class="inner">
          <div class="pull-bottom bottom-left m-b-40 sm-p-l-15">
            <h5 class="text-white no-margin">welcome to pages social</h5>
            <h1 class="text-white no-margin">
              <span class="semi-bold">social</span> cover
            </h1>
          </div>
        </div>
      </div>
    </div>
    <!-- END JUMBOTRON -->
    <div class="container-fluid container-fixed-lg sm-p-l-0 sm-p-r-0">
      <ngx-masonry [options]="{ columnWidth: 40 }" [ordered]="true">
        <div *ngFor="let item of feed" ngxMasonryItem>
          <div
            *ngIf="item.post.type == 'intro'"
            class="card card-borderless no-border bg-transparent full-width"
          >
            <div class="container-fluid p-t-30 p-b-30">
              <div class="row">
                <div class="col-lg-4">
                  <div class="container-xs-height">
                    <div class="row-xs-height">
                      <div
                        class="
                          social-user-profile
                          col-xs-height
                          text-center
                          col-top
                        "
                      >
                        <div
                          class="
                            thumbnail-wrapper
                            d48
                            circular
                            bordered
                            b-white
                          "
                        >
                          <img
                            alt="Avatar"
                            width="55"
                            height="55"
                            data-src-retina="assets/img/profiles/avatar_small2x.jpg"
                            data-src="assets/img/profiles/avatar.jpg"
                            src="assets/img/profiles/avatar.jpg"
                          />
                        </div>
                        <br />
                        <i class="pg-icon text-success m-t-10">tick_circle</i>
                      </div>
                      <div class="col-xs-height p-l-20">
                        <h3 class="no-margin p-b-5">David Nester</h3>
                        <p class="no-margin fs-16">
                          is excited about the new pages design framework
                        </p>
                        <p class="hint-text m-t-5 small">
                          San Fransisco Bay | CEO at Pages.inc
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <p class="no-margin fs-16">
                    Hi My Name is David Nester, &amp; heres my new pages user
                    profile page
                  </p>
                  <p class="hint-text m-t-5 small">
                    I love reading people's about page especially those who are
                    in the same industry as me.
                  </p>
                </div>
                <div class="col-lg-4">
                  <p class="m-b-5 small">1,435 Mutual Friends</p>
                  <ul class="list-unstyled">
                    <li class="m-r-10">
                      <div
                        class="
                          thumbnail-wrapper
                          d32
                          circular
                          b-white
                          m-r-5
                          b-a b-white
                        "
                      >
                        <img
                          width="35"
                          height="35"
                          data-src-retina="assets/img/profiles/1x.jpg"
                          data-src="assets/img/profiles/1.jpg"
                          alt="Profile Image"
                          src="assets/img/profiles/1.jpg"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        class="
                          thumbnail-wrapper
                          d32
                          circular
                          b-white
                          m-r-5
                          b-a b-white
                        "
                      >
                        <img
                          width="35"
                          height="35"
                          data-src-retina="assets/img/profiles/2x.jpg"
                          data-src="assets/img/profiles/2.jpg"
                          alt="Profile Image"
                          src="assets/img/profiles/2.jpg"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        class="
                          thumbnail-wrapper
                          d32
                          circular
                          b-white
                          m-r-5
                          b-a b-white
                        "
                      >
                        <img
                          width="35"
                          height="35"
                          data-src-retina="assets/img/profiles/3x.jpg"
                          data-src="assets/img/profiles/3.jpg"
                          alt="Profile Image"
                          src="assets/img/profiles/3.jpg"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        class="
                          thumbnail-wrapper
                          d32
                          circular
                          b-white
                          m-r-5
                          b-a b-white
                        "
                      >
                        <img
                          width="35"
                          height="35"
                          data-src-retina="assets/img/profiles/4x.jpg"
                          data-src="assets/img/profiles/4.jpg"
                          alt="Profile Image"
                          src="assets/img/profiles/4.jpg"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        class="
                          thumbnail-wrapper
                          d32
                          circular
                          b-white
                          m-r-5
                          b-a b-white
                        "
                      >
                        <img
                          width="35"
                          height="35"
                          data-src-retina="assets/img/profiles/5x.jpg"
                          data-src="assets/img/profiles/5.jpg"
                          alt="Profile Image"
                          src="assets/img/profiles/5.jpg"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        class="
                          thumbnail-wrapper
                          d32
                          circular
                          b-white
                          m-r-5
                          b-a b-white
                        "
                      >
                        <img
                          width="35"
                          height="35"
                          data-src-retina="assets/img/profiles/6x.jpg"
                          data-src="assets/img/profiles/6.jpg"
                          alt="Profile Image"
                          src="assets/img/profiles/6.jpg"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        class="
                          thumbnail-wrapper
                          d32
                          circular
                          b-white
                          m-r-5
                          b-a b-white
                        "
                      >
                        <img
                          width="35"
                          height="35"
                          data-src-retina="assets/img/profiles/7x.jpg"
                          data-src="assets/img/profiles/7.jpg"
                          alt="Profile Image"
                          src="assets/img/profiles/7.jpg"
                        />
                      </div>
                    </li>
                    <li>
                      <div class="thumbnail-wrapper d32 circular b-white">
                        <div class="bg-contrast-high text-center text-white">
                          <span>+34</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <br />
                  <p class="m-t-5 small">More friends</p>
                </div>
              </div>
            </div>
            <!-- END CONTAINER FLUID -->
            <!-- END ITEM -->
          </div>
          <div class="social-container-tes">
            <div
              *ngIf="item.post.type == 'status'"
              class="card social-card col2 padding-20"
            >
              <!-- START ITEM -->
              <form class="simform no-margin" autocomplete="off" stepsform>
                <div class="status-form-inner">
                  <ol class="questions">
                    <li>
                      <span>
                        <label for="status-q1">What's on your mind?</label>
                      </span>
                      <input id="status-q1" name="q1" type="text" />
                    </li>
                    <li>
                      <span>
                        <label for="status-q2">What are you feeling?</label>
                      </span>
                      <input id="status-q2" name="q2" type="text" />
                    </li>
                    <li>
                      <span>
                        <label for="status-q3">What's your location?</label>
                      </span>
                      <input id="status-q3" name="q3" type="text" />
                    </li>
                    <li>
                      <span>
                        <label for="status-q4">Who are you with?</label>
                      </span>
                      <input id="status-q4" name="q4" type="text" />
                    </li>
                  </ol>
                  <!-- /questions -->
                  <button class="submit" type="submit">Send answers</button>
                  <div class="controls">
                    <button class="next"></button>
                    <div class="progress"></div>
                    <span class="number">
                      <span class="number-current"></span>
                      <span class="number-total"></span>
                    </span>
                    <span class="error-message"></span>
                  </div>
                  <!-- / controls -->
                </div>
                <!-- /simform-inner -->
                <span class="final-message"></span>
              </form>
              <!-- /simform -->
              <!-- END ITEM -->
            </div>
            <pgcardsocial
              *ngIf="item.post.type == 'self'"
              Type="status"
              AdditionalClasses="col2"
              Source="{{ item.post.caption }}"
              Timestamp="few seconds ago"
              Author="{{ item.author.name }}"
              Activity="{{ item.post.activity }}"
              Location="{{ item.post.location }}"
              Comments="{{ item.post.comments }}"
              Likes="{{ item.post.likes }}"
              Body="{{ item.post.body }}">
            </pgcardsocial>
            <pgcardsocial
              *ngIf="item.post.type == 'text_image'"
              Type="text"
              AdditionalClasses="col1"
              Source="{{ item.post.caption }}"
              Timestamp="Jul 24"
              Author="{{ item.author.name }}"
              Activity="{{ item.post.activity }}"
              Location="{{ item.post.location }}"
              Image="{{ item.post.image }}"
              Comments="{{ item.post.comments }}"
              Likes="{{ item.post.likes }}"
            >
              <ng-template #AuthorAvatar>
                <img
                  alt="Avatar"
                  width="33"
                  height="33"
                  pgRetina
                  src2x="{{ item.author.avatar2x }}"
                  src1x="{{ item.author.avatar }}"
                  src="{{ item.author.avatar2x }}"
                />
              </ng-template>
              <ng-template #CustomBody>
                <p>{{ item.post.body }}</p>
              </ng-template>
            </pgcardsocial>
            <pgcardsocial
              *ngIf="item.post.type == 'text'"
              Type="text"
              AdditionalClasses="col1"
              Source="{{ item.post.caption }}"
              Timestamp="Jul 24"
              Author="{{ item.author.name }}"
              Activity="{{ item.post.activity }}"
              Location="{{ item.post.location }}"
            >
              <ng-template #AuthorAvatar>
                <img
                  alt="Avatar"
                  width="33"
                  height="33"
                  pgRetina
                  src2x="{{ item.author.avatar2x }}"
                  src1x="{{ item.author.avatar }}"
                  src="{{ item.author.avatar2x }}"
                />
              </ng-template>
              <ng-template #CustomBody>
                <p>{{ item.post.body }}</p>
              </ng-template>
            </pgcardsocial>

            <pgcardsocial
              *ngIf="item.post.type == 'image_text'"
              Type="image"
              AdditionalClasses="share-other col1"
              Source="{{ item.post.caption }}"
              Timestamp="{{ item.post.timestamp }}"
              Author="{{ item.author.name }}"
              Activity="{{ item.post.activity }}"
              Location="{{ item.post.location }}"
              Image="{{ item.post.image }}"
              Comments="{{ item.post.comments }}"
              Likes="{{ item.post.likes }}"
            >
              <ng-template #AuthorAvatar>
                <img
                  alt="Avatar"
                  width="33"
                  height="33"
                  pgRetina
                  src2x="{{ item.author.avatar2x }}"
                  src1x="{{ item.author.avatar }}"
                  src="{{ item.author.avatar2x }}"
                />
              </ng-template>
              <ng-template #CustomBody>
                <p>{{ item.post.body }}</p>
              </ng-template>
            </pgcardsocial>
            <!-- START ITEM -->
            <pgcardsocial
              *ngIf="item.post.type == 'news'"
              Type="widget"
              AdditionalClasses="col1"
              Title="News"
              TitleClass="text-success"
              Comments="{{ item.post.comments }}"
              Source="{{ item.post.activity }}"
              Timestamp="{{ item.post.timestamp }}"
            >
              <ng-template #CustomBody>
                <h3>{{ item.post.body }}</h3>
              </ng-template>
            </pgcardsocial>
            <!-- END ITEM -->
            <!-- START ITEM -->
            <pgcardsocial
              *ngIf="item.post.type == 'stocks'"
              Type="widget"
              AdditionalClasses="col1"
              Title="Stock Market"
              TitleClass="text-success"
              Comments="{{ item.post.comments }}"
              Source="{{ item.post.activity }}"
              Timestamp="{{ item.post.timestamp }}"
            >
              <ng-template #CustomBody>
                <h5 class="hint-text no-margin">{{ item.post.activity }}</h5>
                <h5 class="small hint-text no-margin">
                  {{ item.post.caption }}
                </h5>
                <h3>
                  111.25
                  <span class="text-success">
                    <i class="fa fa-sort-up small text-success"></i> 0.15
                    (0.13%)</span
                  >
                </h3>
              </ng-template>
            </pgcardsocial>
          </div>

          <!-- END ITEM -->
        </div>
      </ngx-masonry>
    </div>
  </div>
</div>
