<div class="card-header" [attr.aria-expanded]="_active" (click)="clickHeader($event)" role="tab">
  <ng-template [ngIf]="Title">
      <h4 class="card-title">
        <a href="javascript:;" [ngClass]="{'collapsed':!_active}">
        {{ Title }}
        </a>
      </h4>
  </ng-template>
  <ng-template [ngIf]="!Title">
    <ng-content select="[collapse-title]"></ng-content>
  </ng-template>
</div>
<div class="collapse" [ngClass]="{'show':true,'active':_active,'inactive':!_active}" [@collapseState]="_active?'active':'inactive'">
  <div class="card-body">
    <ng-content></ng-content>
  </div>
</div>