<pgcard TimeOut="3000" AdditionalClasses="widget-9 card no-border no-margin widget-loader-bar {{_bgColor}}" Maximize="false" Toggle="false"
 Close="false" BodyClass="no-padding">
	<ng-template #CardTitle>
		<span class="font-montserrat fs-11 all-caps">
			Weekly Sales
			<i class="fa fa-chevron-right"></i>
		</span>
	</ng-template>
	<div class="full-height d-flex flex-column">
			<div class="p-l-20">
				<h3 class="no-margin p-b-5">$23,000</h3>
				<span class="d-flex align-items-center"> 
					<i class="pg-icon m-r-5">arrow_down</i>
					<span class="small hint-text">65% lower than last month</span>
                </span>
			</div>
			<div class="mt-auto">
				<div class="progress progress-small m-b-20">
					<!-- START BOOTSTRAP PROGRESS (http://getbootstrap.com/components/#progress) -->
					<div class="progress-bar progress-bar-white" style="width:45%"></div>
					<!-- END BOOTSTRAP PROGRESS -->
				</div>
			</div>
		</div>
</pgcard>
