<pgcard TimeOut="3000" AdditionalClasses="widget-16 card no-border  no-margin widget-loader-circle" Maximize="false"
  Toggle="false" Close="false" BodyClass="no-padding">
  <ng-template #CardTitle>
    <span class="font-montserrat fs-11 all-caps">
      PAGE OPTIONS
      <i class="fa fa-chevron-right"></i>
    </span>
  </ng-template>
  <div class="widget-16-header p-r-20 p-l-20 p-t-10 d-flex">
    <span class="icon-thumbnail bg-contrast-low pull-left text-color pg-icon">ws</span>
    <div class="flex-1 full-width overflow-ellipsis">
      <p class="hint-text all-caps font-montserrat  small no-margin overflow-ellipsis ">Pages
        name</p>
      <h6 class="no-margin overflow-ellipsis ">Webarch Sales Analysis</h6>
    </div>
  </div>
  <div class="p-l-20 p-r-45 p-t-25 p-b-25">
    <ul class="list-inline no-margin">
      <li class="p-r-25">
        <p class="hint-text all-caps font-montserrat small no-margin ">Views</p>
        <p class="all-caps font-montserrat  no-margin text-success ">14,256</p>
      </li>
      <li class="p-r-25">
        <p class="hint-text all-caps font-montserrat small no-margin ">Today</p>
        <p class="all-caps font-montserrat  no-margin text-warning ">24</p>
      </li>
      <li class="p-r-25">
        <p class="hint-text all-caps font-montserrat small no-margin ">Week</p>
        <p class="all-caps font-montserrat  no-margin text-success ">56</p>
      </li>
    </ul>
  </div>
  <div class="relative no-overflow" #chartContainer>
    <div class="widget-16-chart line-chart" data-line-color="success" data-points="true" data-point-color="white"
      data-stroke-width="2">
	  <div echarts [options]="chartOption" class="area-chart"></div>
      <!-- <nvd3 [options]="nvd3LineOptions" [data]="nvd3LineData"></nvd3> -->
    </div>
  </div>
  <div class="b-b b-t b-grey p-l-20 p-r-20 p-b-15 p-t-15">
    <div class="form-check switch switch-lg success full-width right d-flex m-b-0">
      <input type="checkbox" id="pagesSwitch1">
      <label for="pagesSwitch1" class="full-width">Post is Public</label>
    </div>
  </div>
  <div class="b-b b-grey p-l-20 p-r-20 p-b-15 p-t-15">
    <div class="form-check switch switch-lg success full-width right d-flex m-b-0">
      <input type="checkbox" id="pagesSwitch2" checked>
      <label for="pagesSwitch2" class="full-width">Maintenance mode</label>
    </div>
    <div class="clearfix"></div>
  </div>
  <div class="p-l-20 p-r-20 p-t-10 p-b-10 ">
    <p class="pull-left no-margin hint-text">Super secret options</p>

    <a href="#" class="btn-circle-arrow b-grey pull-right"><i class="pg-icon">chevron_down</i></a>

    <div class="clearfix"></div>
  </div>
</pgcard>
