<pg-container>

  <div class="row">
    <div class="col-md-2"></div>

    <div class="col-md-8">
      <div class="card card-transparent">
        <div class="card-body">
          <div *ngFor="let category of allCategories" class="b-b b-grey m-b-20">
            <h3>
              <b>{{category.categoryName}} <i class="{{category.categoryIcon}}" style="--fa-animation-iteration-count: 1;"></i> </b>
            </h3>
            <div *ngFor="let item of category.items">
              <p style="margin-bottom: 0px;">
                {{item.name}} 
                <a *ngIf="item.websiteUrl" target="_blank" href="{{item.websiteUrl}}" tooltip="{{item.nameIconTooltip}}"><i class="fa-solid fa-link"></i></a>
                <i *ngIf="!item.websiteUrl && item.nameIcon" class="{{item.nameIcon}}" tooltip="{{item.nameIconTooltip}}"></i>
              </p>
              <p class="small-text hint-text">
                {{item.description}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-2"></div>
  </div>

</pg-container>