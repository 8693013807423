import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'social-post-tile-widget',
  templateUrl: './social-post-tile-widget.component.html',
  styleUrls: ['./social-post-tile-widget.component.scss']
})
export class SocialPostTileWidgetComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
