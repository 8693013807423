<pgcard TimeOut="3000" AdditionalClasses="widget-10 card no-border bg-white no-margin widget-loader-bar"
  Maximize="false" Toggle="false" Close="false" ProgressType="bar">
  <ng-template #CardTitle>
    <span class="font-montserrat fs-11 all-caps">
      Weekly Sales
      <i class="fa fa-chevron-right"></i>
    </span>
  </ng-template>
  <div class="row">
    <div class="col-sm-12">
      <h4 class="no-margin p-b-5 text-danger semi-bold">APPL 2.032</h4>

      <div class="d-flex align-items-center pull-left small">
        <span>WMHC</span>
        <span class=" text-success font-montserrat d-flex align-items-center">
          <i class="pg-icon">arrow_up</i> 21%
        </span>
      </div>
      <div class="d-flex align-items-center pull-left m-l-20 small">
        <span>HCRS</span>
        <span class=" text-danger font-montserrat d-flex align-items-center">
          <i class="pg-icon">arrow_down</i> 21%
        </span>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <div class="p-t-5 full-width">
    <a href="#" class="btn-circle-arrow b-grey"><i class="pg-icon">chevron_down</i></a>
    <span class="hint-text small">Show more</span>
  </div>
</pgcard>
