<pg-header boxed="true">
  <!-- <div class="header-inner header-md-height">
    <a href="javascript:void(0);" class="btn-icon-link header-icon toggle-sidebar d-lg-none" style="margin-left: 3px;"
      (click)="toggleHorizontalMenuMobile()">
      <i class="fa-solid fa-bars fa-lg"></i>
    </a>
    <div class="">
      <a href="javascript:void(0);" class="toggle-secondary-sidebar align-items-center"
        (click)="toggleSecondarySideBar()" *ngIf="_layoutOption === 'email'">
        <span class="d-flex align-items-center">
          Inbox <span class="text-info">(12)</span> <span class="pg-icon">drop_down</span>
        </span>
      </a>

      <a href="javascript:void(0)" class="search-link d-lg-inline-block d-none" (click)="openSearch($event)"
        style="margin-left: 4px;">
        <i class="fa-solid fa-magnifying-glass"></i>Type anywhere to <span class="bold">search</span>
      </a>
    </div>
    <div class="d-flex align-items-center">
      <div class="dropdown pull-right d-lg-block" dropdown>
        <button class="profile-dropdown-toggle" type="button" dropdownToggle id="profileDropdown"
          aria-label="profile dropdown">
          <span class="thumbnail-wrapper d32 circular inline">
            <img src="assets/img/profiles/avatar.jpg" alt="" pgRetina src1x="assets/img/profiles/avatar.jpg"
              src2x="assets/img/profiles/avatar_small2x.jpg" width="32" height="32">
          </span>
        </button>
        <div class="dropdown-menu dropdown-menu-right profile-dropdown" *dropdownMenu role="menu">
          <a href="#" class="dropdown-item"><span>
              {{siteJson.fullName}} <br />
              <span class="hint-text small-text">{{siteJson.title}}</span>
            </span></a>
          <div class="dropdown-divider"></div>

          <a [routerLink]="'resume'" class="dropdown-item hint-text"><i
              class="fa-solid fa-user-tie fa-fw"></i>Resume</a>
          <a [routerLink]="'apps-and-tools'" class="dropdown-item hint-text"><i
              class="fa-solid fa-toolbox fa-fw"></i>Apps & Tools</a>
          <a [routerLink]="'setup'" class="dropdown-item hint-text"><i
              class="fa-solid fa-house-laptop fa-fw"></i>Setup</a>

          <div class="dropdown-divider"></div>
          <a target="_blank" href="{{siteJson.social.githubProfileUrl}}" class="dropdown-item">GitHub</a>
          <a target="_blank" href="{{siteJson.social.stackoverflowProfileUrl}}"
            class="dropdown-item">Stackoverflow</a>
          <a target="_blank" href="https://dev.to/kircali" class="dropdown-item">Dev.to</a>
          <div class="dropdown-divider"></div>

          <a [routerLink]="'contact'" class="dropdown-item hint-text"><i
            class="fa-regular fa-envelope fa-fw"></i>Contact</a>

          <a href="#" class="dropdown-item">Settings</a>
          <a href="#" class="dropdown-item">Logout</a>
          <div class="dropdown-divider"></div>
          <span class="dropdown-item fs-12 hint-text">Last edited by OK<br />on Friday at 5:27PM</span>
        </div>
      </div>
      <a href="javascript:void(0)" class="header-icon btn-icon-link m-l-5 sm-no-margin d-inline-block btn-icon-link"
        (click)="openQuickView($event)">
        <i class="pg-icon">menu_add</i>
      </a>
    </div>
  </div> -->
  <div class="header-inner header-lg-height title-bar">
    <!-- <div class="brand inline align-self-end">
      <img src="assets/img/logo_s.png" alt="logo" pgRetina src1x="assets/img/logo_s.png"
        src2x="assets/img/logo_s_2x.png" width="17" height="20">
      <a href="javascript:void(0)">
        <h2 class="page-title" (click)="redirectToHome()">
          <i class="fa-solid fa-signature fa-fw"></i>
        </h2>
      </a>
    </div> -->
    <a href="javascript:void(0);" class="btn-icon-link header-icon toggle-sidebar d-lg-none"
      style="margin-left: 3px; margin-top: 11px;" (click)="toggleHorizontalMenuMobile()">
      <i class="fa-solid fa-bars fa-lg"></i>
    </a>
    <h2 class="page-title brand align-self-end" [ngClass]="{'brand': !_isHomePage}" (click)="redirectToHome()">
      <a style="margin-left: 0px;" class="page-title" href="javascript:void(0)">{{siteJson.fullName}}</a>

      &nbsp;&nbsp;<span style="opacity: 0.5;"><i class="fa-solid {{_pageIcon}}"></i> {{_pageTitle}}</span>
    </h2>

    <!-- <h2 class="page-title inline align-self-end" style="opacity: 0.5; margin-left: 15px">
      <i class="fa-solid fa-xs {{_pageIcon}}"></i> {{_pageTitle}}
    </h2> -->

    <pg-horizontal-menu [Items]="menuItems" HideExtra="0" [AutoHideItems]="false" style="margin-top: 26px;">
      <ng-template #mobileSidebarFooter>
        <!-- <a href="javascript:void(0)" class="search-link d-flex justify-content-between align-items-center d-lg-none"
          (click)="openSearch($event)">Tap here to search <i class="pg pg-search float-right"></i></a> -->
      </ng-template>
    </pg-horizontal-menu>


  </div>
  <!-- @oguzhankircali activate to see horizontal menu -->
  <!-- <pg-horizontal-menu [Items]="menuItems" HideExtra="0" [AutoHideItems]="false">
    <ng-template #mobileSidebarFooter>
      <a href="javascript:void(0)" class="search-link d-flex justify-content-between align-items-center d-lg-none"
        (click)="openSearch($event)">Tap here to search <i class="pg pg-search float-right"></i></a>
    </ng-template>
  </pg-horizontal-menu> -->

</pg-header>

<!-- MODAL STICK UP SMALL ALERT -->
<div bsModal #slideRight="bs-modal" class="modal fade slide-right" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content-wrapper">
      <div class="modal-content">
        <button type="button" class="close m-t-10" (click)="slideRight.hide()" aria-hidden="true"><i
            class="pg-icon">close</i>
        </button>
        <div class="container-xs-height full-height">
          <div class="row-xs-height">
            <div class="modal-body col-xs-height">
              <h3>
                <b>About Site</b>
              </h3>
              <div *ngFor="let category of aboutSiteJson.categories" class="b-b b-grey m-b-20 m-t-20">
                <h5>
                  <b>{{category.title}} </b>
                </h5>
                <div *ngFor="let item of category.items">
                  <p style="margin-bottom: 0px;">
                    {{item.name}}
                  </p>
                  <p class="small-text hint-text">
                    {{item.label}}
                    <a target="_blank" href="{{item.link}}"><i class="fa-solid fa-link"></i></a>
                  </p>
                </div>
              </div>
              <button type="button" class="btn btn-primary btn-block" (click)="slideRight.hide()">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- END MODAL STICK UP SMALL ALERT -->

<div class="page-container">
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper {{_pageContainerClass}}">
    <!-- START PAGE CONTENT -->
    <div class="content {{_contentClass}}">
      <router-outlet></router-outlet>
    </div>
    <!-- END PAGE CONTENT -->
    <!-- START COPYRIGHT -->
    <!-- START CONTAINER FLUID -->
    <ng-template [ngIf]="_footer">
      <div class=" container   container-fixed-lg footer">
        <div class="copyright sm-text-center">
          <p class="small no-margin pull-left sm-pull-reset">
            <span class="hint-text">Copyright &copy; 2023</span>
            &nbsp;<span class="font-montserrat">{{siteJson.lastName}}</span>.&nbsp;
            <!-- <span class="hint-text">All rights reserved. </span> -->
            <span class="sm-block">
              <a href="/rss.xml" target="_blank" class="m-l-10 m-r-10"><i class="fa-solid fa-square-rss fa-fw"></i>
                RSS</a>
              <span class="muted">|</span>
              <a href="/feed.xml" target="_blank" class="m-l-10 m-r-10"><i class="fa-solid fa-square-rss fa-fw"></i>
                Atom</a>
              <!-- <span class="muted">|</span>
              <a href="/feed.json" target="_blank" class="m-l-10 m-r-10"><i class="fa-solid fa-square-rss fa-fw"></i>
                JSON</a> -->
              <span class="muted">|</span>
              <a href="/sitemap.xml" target="_blank" class="m-l-10 m-r-10"><i class="fa-solid fa-sitemap fa-fw"></i>
                Sitemap</a>
            </span>
          </p>
          <p class="small no-margin pull-right sm-pull-reset">
            <!-- <a href="javascript:void(0);" class="m-l-10 m-r-10"
              (click)="slideRight.show()">
              <i class="fa-solid fa-code fa-fw"></i>
              About Site</a>  -->
            Hand-crafted
            <a [routerLink]="'contact'" class="m-l-10 m-r-10"><i class="fa-regular fa-envelope fa-fw"></i> Contact</a>
            <a [routerLink]="'about-site'" class="m-l-10 m-r-10"><i class="fa-solid fa-code"></i> About Site</a>

          </p>
          <div class="clearfix"></div>
        </div>
      </div>
    </ng-template>
    <!-- END COPYRIGHT -->
  </div>
  <!-- END PAGE CONTENT WRAPPER -->
</div>
<!-- END PAGE CONTAINER -->
<app-search-overlay></app-search-overlay>
<app-quickview></app-quickview>