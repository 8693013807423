export class Post {
    title: string;
    summary: string;
    permalink: string;
    htmlContent: string;
    publishDate: string;
    language: string;
    unlisted: string;
    imagePath: string;
    dateModified: Date;
    isPage: boolean;
}
