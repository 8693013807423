<pgcard TimeOut="3000" AdditionalClasses="widget-3 card no-border bg-complete no-margin widget-loader-bar full-height" MinimalHeader="true" BodyClass="no-padding" HeaderClass="hidden">
	<swiper [config]="config" [(index)]="index">
		<div class="slide-front tiles slide active">
			<div class="padding-25">
				<div class="pull-top">
					<div class="pull-left visible-lg visible-xlg">
						<i class="pg pg-map"></i>
					</div>
					<div class="pull-right">
						<ul class="list-inline ">
							<li>
								<a href="javascript:void(0)" class="no-decoration text-white">
									<i class="pg-icon">comment_alt</i>
								</a>
							</li>
							<li>
								<a href="javascript:void(0)" class="widget-3-fav no-decoration text-white">
									<i class="pg-icon">heart_outline</i>
								</a>
							</li>
						</ul>
					</div>
					<div class="clearfix"></div>
				</div>
				<div class="pull-bottom p-b-30">
					<p class="p-t-10 fs-12 p-b-5 text-white no-margin">21 Jan</p>

					<h3 class="no-margin text-white p-b-10">Carefully
						<br>designed for a
						<br>great
						<span class="semi-bold">experience</span>
					</h3>
				</div>
			</div>
		</div>
		<div class="slide-back tiles">
			<div class="padding-25">
				<div class="pull-top">
					<div class="pull-left visible-lg visible-xlg">
						<i class="pg"></i>
					</div>
					<div class="pull-right">
						<ul class="list-inline ">
							<li>
								<a href="javascript:void(0)" class="no-decoration text-white">
									<i class="pg-icon">comment_alt</i>
								</a>
							</li>
							<li>
								<a href="javascript:void(0)" class="widget-3-fav no-decoration text-white">
									<i class="pg-icon">heart_outline</i>
								</a>
							</li>
						</ul>

					</div>
					<div class="clearfix"></div>
				</div>
				<div class="pull-bottom p-b-25">
					<p class="p-t-10 fs-12 p-b-5 text-white no-margin">21 Jan</p>

					<h3 class="no-margin text-white p-b-10">A whole new
						<br>
						<span class="semi-bold">page</span>
					</h3>
				</div>
			</div>
		</div>
	</swiper>
</pgcard>
