import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import SiteJson from 'assets/data/site.json';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponentPage implements OnInit {
  slug: string;
  siteJson;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.slug = this.route.snapshot.paramMap.get('slug');

    this.siteJson = SiteJson;
  }
}
