<!-- START CONTAINER FLUID -->
<div class="container-fluid padding-25 sm-padding-10 sm-gutter">
  <div class="row">
    <div class="col-lg-4 col-xl-3 col-xlg-2 ">
      <div class="row">
        <div class="col-md-12 m-b-10">
          <!-- START WIDGET -->
          <graph-tile-flat-widget BgColor="bg-warning"></graph-tile-flat-widget>

          <!-- END WIDGET -->
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 m-b-10">
          <!-- START WIDGET -->
          <progress-tile-flat-widget BgColor="bg-success"></progress-tile-flat-widget>
          <!-- END WIDGET -->
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 m-b-10">
          <!-- START WIDGET -->
          <stat-tile-widget></stat-tile-widget>

          <!-- END WIDGET -->
        </div>
      </div>
    </div>
    <div class="col-lg-8 col-xl-9 col-xlg-6 m-b-10">
      <div class="row full-height">
        <div class="col-md-12">
          <!-- START WIDGET -->
          <graph-widget ChartHighlightsClass="col-sm-4" ChartWrapperClass="col-sm-8"></graph-widget>
          <!-- END WIDGET -->
        </div>
      </div>
    </div>
    <div class="col-lg-6 hidden-lg visible-xlg col-xlg-4 m-b-10">
      <!-- START WIDGET -->
      <stacked-bar-widget></stacked-bar-widget>
      <!-- END WIDGET -->
    </div>
  </div>
  <div class="row m-b-10">
    <div class="col-lg-8 sm-p-b-10 md-p-b-10">
      <!-- START WIDGET -->
      <map-widget IsGrayscale="true"></map-widget>
      <!-- END WIDGET -->
    </div>
    <div class="col-lg-4">
      <!-- START WIDGET -->
      <realtime-widget></realtime-widget>

      <!-- END WIDGET -->
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 visible-lg hidden-xlg sm-m-b-10">
      <!-- START WIDGET -->
      <!-- TODO -->
      <!-- <stacked-bar-widget-basic></stacked-bar-widget-basic> -->
      <stacked-bar-widget></stacked-bar-widget>
      <!-- END WIDGET -->
    </div>
    <div class="col-md-4 col-lg-3 col-xlg-3 m-b-10 visible-xlg">
      <!-- START WIDGET -->
      <graph-options-widget></graph-options-widget>
      <!-- END WIDGET -->
    </div>
    <div class="col-xlg-3 visible-xlg ">
      <div class="row">
        <div class="col-xlg-12">
          <!-- START WIDGET -->
          <social-post-tile-widget></social-post-tile-widget>
          <!-- END WIDGET -->
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-8 col-xlg-6 md-m-t-10">
      <!-- START WIDGET -->
      <weather-widget Type="compact"></weather-widget>
      <!-- END WIDGET -->
    </div>
  </div>
</div>
<!-- END CONTAINER FLUID -->