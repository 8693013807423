<pg-container>

  <div class="row">
    <div class="col-md-2"></div>

    <div class="col-md-8">

      <div class="card card-transparent">
        <div class="card-body">
          <div class="error-page">
            <div class="d-flex justify-content-center full-height full-width align-items-center">
              <div class="error-container text-center">
                <h1 class="error-number">{{error}}</h1>
                <h2 class="semi-bold">Sorry but we couldn't find this page</h2>
                <p class="p-b-10">This page you are looking for does not exsist! <a href="javascript:void(0)" [routerLink]="'/'">go to home</a>
                </p>
                <div class="error-container-innner text-center">
                  <!-- <form class="error-form">
                    <div class=" transparent text-left">
                      <div class="form-group form-group-default input-group" pgFormGroupDefault>
                        <div class="form-input-group">
                          <label>Search</label>
                          <input class="form-control" placeholder="Try searching the missing page" type="email">
                        </div>
                        <div class="input-group-append">
                          <span class="input-group-text transparent">
                            <span class="pg-icon">search</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>           -->
                </div>
              </div>
            </div>          
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-2"></div>
  </div>

</pg-container>


