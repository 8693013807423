<pgcard TimeOut="3000" RefreshColor="dark"
  AdditionalClasses="widget-1 card no-border bg-complete no-margin widget-loader-circle-lg full-height"
  MinimalHeader="true">
  <div class="pull-bottom bottom-left bottom-right ">
    <span class="label font-montserrat fs-11">EXPLORE</span>
    <br>

    <h2 class="text-white">Type anywhere to get Quick Search</h2>

    <p class="text-white hint-text">Learn More at Project Pages</p>

    <div class="row stock-rates m-t-15">
      <div class="company col-4">
        <div>
          <p class="font-montserrat text-success no-margin fs-16">
            <i class="pg-icon">arrow_up</i> +0.95%
            <span class="font-arial text-white fs-12 hint-text m-l-5">546.45</span>
          </p>

          <p class="bold text-white no-margin fs-11 font-montserrat lh-normal">
            AAPL
          </p>
        </div>
      </div>
      <div class="company col-4">
        <div>
          <p class="font-montserrat no-margin fs-16">
            <i class="pg-icon">arrow_down</i> -0.34%
            <span class="font-arial text-white fs-12 hint-text m-l-5">345.34</span>
          </p>

          <p class="bold text-white no-margin fs-11 font-montserrat lh-normal">
            YAHW
          </p>
        </div>
      </div>
      <div class="company col-4">
        <div class="pull-right">
          <p class="font-montserrat text-success no-margin fs-16">
            <i class="pg-icon">arrow_up</i> +0.95%
            <span class="font-arial text-white fs-12 hint-text m-l-5">278.87</span>
          </p>

          <p class="bold text-white no-margin fs-11 font-montserrat lh-normal">
            PAGES
          </p>
        </div>
      </div>
    </div>
  </div>
</pgcard>
