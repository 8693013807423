

<pg-container>
  <!-- <div class="gallery-filters p-t-20 p-b-10">
    <ul class="list-inline text-right">
      <li class="hint-text">Show only:</li>
      &nbsp;
      <li>
        <i class="fa-solid fa-book-open-reader"></i>
        <a href="javascript:void(0)" class="active text-color p-r-5 p-l-5">Reading</a>
      </li>

      &nbsp;
      <li>
        <i class="fa-solid fa-book"></i>
        <a href="javascript:void(0)" class="text-color hint-text p-r-5 p-l-5">Read</a>
      </li>
      &nbsp;
      <li>
        <i class="fa-regular fa-heart"></i>
        <a href="javascript:void(0)" class="text-color hint-text p-r-5 p-l-5">Wants</a>
      </li>
    </ul>
  </div> -->
  <!-- <pg-breadcrumb>
    <ol class="breadcrumb">
      <li>Currently Reading</li>
    </ol>
  </pg-breadcrumb>  -->

  <div class="card card-transparent">
    <div class="card-body">
      <div class="row">
        <ul class="navbar-nav col-sm-12 col-md-12  col-lg-7">
          <li class="nav-item dropdown" dropdown>
            <a class="nav-link dropdown-toggle align-items-center" href="javascript:void(0);" id="navbarDropdownMenuLink" dropdownToggle aria-haspopup="true" aria-expanded="false">
              <i class="fa-solid fa-filter"></i> {{filterLabel}} ({{filteredBooks.length}})
            </a>
            <div class="dropdown-menu"   *dropdownMenu aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" href="javascript:void(0);" (click)="filterBooks(false)">Currently Reading</a>
              <a class="dropdown-item" href="javascript:void(0);" (click)="filterBooks(true)">Read</a>
              <a class="dropdown-item" href="javascript:void(0);" (click)="filterBooks(null)">Wants to Read</a>
              
              <div class="divider"></div>
              <a class="dropdown-item" href="javascript:void(0);" (click)="clearFilter()">All</a>
            </div>
          </li>
        </ul>
      </div>
      <div class="row">
        <p *ngIf="filteredBooks.length == 0">No books for now.</p>
        <div class="col-xs-2" *ngFor="let book of filteredBooks">
          <div class="card social-card share _additionalClasses">
            <div class="card-content flex-1 b-b b-grey cover-image" data-pages-bg-image style="background-image: url('{{book.coverUrl}}');" tooltip="{{book.name}}, {{book.author}}">
            </div>
            <div class="card-footer" tooltip="{{book.name}}, {{book.author}}">
              <div class="time" *ngIf="book.isRead == false">
                <b><span>Started at</span></b> <br>
                {{ book.startedReadingAt | date:'mediumDate' }}
              </div>
              <div class="time pull" *ngIf="book.isRead == true">
                <b><span>Finished at</span></b> <br>
                {{ book.finishedReadingAt | date:'mediumDate' }}
              </div>
              <div class="time" *ngIf="book.isRead == null">
                <b><span class="text-default">Wants to Read since</span></b> <br>
                {{ book.createdAt | date:'mediumDate' }}
              </div>


              <div class="time pull-right" *ngIf="book.isRead == false">
                <b>Completed</b> <br>
                <span class="pull-right">%{{ book.readPercentage }}</span>
              </div>
              <div class="time pull-right" *ngIf="book.isRead == true">
                <span class="pull-right"><b>My Rating</b></span><br>
                <span class="pull-right">
                  <i class="fa-solid fa-heart text-danger" *ngIf="book.rating >= 4.5"></i>
                  {{ book.rating | number:'1.1-1':'en-US'}}/5
                </span>
              </div>

              <div class="time pull-right" *ngIf="book.isRead == null">
                <span class="pull-right"><i class="text-primary fa-regular fa-bookmark"></i> </span><br>
                <!-- <span class="pull-right">{{ book.rating | number:'1.1-1':'en-US'}}/5</span> -->
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</pg-container>