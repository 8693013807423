import { HttpClient } from '@angular/common/http';
import { Component, OnInit, isDevMode } from '@angular/core';
import { environment } from 'environments/environment.prod';

@Component({
  selector: 'app-reading',
  templateUrl: './reading.component.html',
  styleUrls: ['./reading.component.scss']
})
export class ReadingComponent implements OnInit {
  accessToken;
  allBooks: any[] = [];
  filteredBooks: any[] = [];
  filterLabel = 'All';
  isSessionStorageActive = false;
  sessionStorageKey = '#allBooksFromSessionStorageKey';

  selectedColor;
  currentTab = 0;
  isDevMode: boolean;


  constructor(private http: HttpClient) { }

  ngOnInit() {

    this.getBooks();
  }

  private getToken() {
    this.http.post<any>(environment.apiUrl + '/security/get-token', {
      username: 'oguzhankircali',
      password: 'Qwe.123!api'
    }).subscribe(data => {
      this.accessToken = data.token;

      this.getBooks();
    });
  }

  filterBooks(isReadFilterValue) {

    if (isReadFilterValue === true) {
      this.filterLabel = 'Read';
    } else if (isReadFilterValue === false) {
      this.filterLabel = 'Currently Reading';
    } else if (isReadFilterValue === null) {
      this.filterLabel = 'Wants to Read';
    }

    this.filteredBooks = this.allBooks.filter((obj) => {
      return obj.isRead === isReadFilterValue;
    });
  }

  clearFilter() {
    this.filteredBooks = this.allBooks;
    this.filterLabel = 'All';
  }

  private getBooks() {

    if (this.isSessionStorageActive) {
      const allBooksFromLocalStorage = window.sessionStorage.getItem(this.sessionStorageKey);
      if (allBooksFromLocalStorage != null && allBooksFromLocalStorage !== undefined) {
        this.allBooks = this.filteredBooks = JSON.parse(allBooksFromLocalStorage);
        return;
      }
    }
    this.http.get<any>(environment.apiUrl + '/books').subscribe(data => {
      this.allBooks = this.filteredBooks = data;

      if (this.isSessionStorageActive) {
        window.sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(data));
      }

      this.filterBooks(false);
    });
  }
}
