import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'environments/environment.prod';
import { RootLayout } from '../root/root.component';
import SiteJson from 'assets/data/site.json';
import AboutSiteJson from 'assets/data/about-site.json';

declare var pg: any;
@Component({
  selector: 'executive-layout',
  templateUrl: './executive.component.html',
  styleUrls: ['./executive.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExecutiveLayout extends RootLayout implements OnInit {

  siteJson;
  aboutSiteJson = AboutSiteJson;
  menuItems = [
    {
      label: 'Home',
      routerLink: '/',
      iconType: 'pg',
      iconName: 'home',
      iconClass: 'fa-solid fa-terminal fa-fw'
    },
    {
      label: 'Blog',
      routerLink: 'blog',
      iconType: 'letter',
      iconName: 'fl',
      iconClass: 'fa-solid fa-feather fa-fw'
    },
    {
      label: 'Resume',
      routerLink: 'resume',
      iconType: 'letter',
      iconName: 't',
      iconClass: 'fa-solid fa-user-tie fa-fw'
    },
    {
      label: 'Reading',
      routerLink: 'reading',
      iconType: 'letter',
      iconName: 't',
      iconClass: 'fa-solid fa-book-open-reader fa-fw'
    },
    // {
    //   label: 'Apps & Tools',
    //   routerLink: 'apps-and-tools',
    //   iconType: 'letter',
    //   iconName: 't',
    //   iconClass: 'fa-solid fa-toolbox fa-fw'
    // },
    // {
    //   label: 'Setup',
    //   routerLink: 'setup',
    //   iconType: 'letter',
    //   iconName: 't',
    //   iconClass: 'fa-solid fa-house-laptop fa-fw'
    // },
    // {
    //   label: 'Coffee',
    //   routerLink: 'coffee',
    //   iconType: 'letter',
    //   iconName: 't',
    //   iconClass: 'fa-solid fa-mug-hot fa-fw'
    // },
    // {
    //   label: 'About Site',
    //   routerLink: 'about-site',
    //   iconType: 'letter',
    //   iconName: 't',
    //   iconClass: 'fa-solid fa-code fa-fw'
    // },
    {
      label: 'Setups',
      iconType: 'pg',
      iconName: 'bag',
      iconClass: 'fa-solid fa-house-laptop fa-fw',
      toggle: 'close',
      mToggle: 'close',
      submenu: [
        {
          label: 'Website',
          routerLink: 'about-site',
          iconType: 'letter',
          iconName: 't',
          iconClass: 'fa-solid fa-house-laptop fa-fw'
        },
        {
          label: 'App',
          routerLink: 'apps-and-tools',
          iconType: 'letter',
          iconName: 't',
          iconClass: 'fa-solid fa-toolbox fa-fw'
        },
        {
          label: 'Desk',
          routerLink: 'setup',
          iconType: 'letter',
          iconName: 't',
          iconClass: 'fa-solid fa-house-laptop fa-fw'
        },
        {
          label: 'Coffee',
          routerLink: 'coffee',
          iconType: 'letter',
          iconName: 't',
          iconClass: 'fa-solid fa-house-laptop fa-fw'
        }
      ]
    },
    // {
    //   label: 'Apps & Tools',
    //   routerLink: 'apps-and-tools',
    //   iconType: 'letter',
    //   iconName: 't',
    //   iconClass: 'fa-solid fa-toolbox fa-fw'
    // },
    // {
    //   label: 'Setup',
    //   routerLink: 'setup',
    //   iconType: 'letter',
    //   iconName: 't',
    //   iconClass: 'fa-solid fa-house-laptop'
    // },
    // {
    //   label: 'Contact',
    //   routerLink: 'contact',
    //   iconClass: 'fa-solid fa-envelope'
    // }
  // {
  //   label: 'Contact',
  //   routerLink: 'contact',
  //   iconType: 'letter',
  //   iconName: 't'
  // },
  // {
  //   label: 'Timeline',
  //   routerLink: 'timeline',
  //   iconType: 'letter',
  //   iconName: 't'
  // }
    // {
    //   label: 'Dashboard',
    //   details: '12 New Updates',
    //   routerLink: 'dashboard',
    //   iconType: 'pg',
    //   iconName: 'home'
    // },
    // {
    //   label: 'Forms',
    //   iconType: 'pg',
    //   iconName: 'form',
    //   toggle: 'close',
    //   mToggle: 'close',
    //   submenu: [
    //     {
    //       label: 'Social',
    //       routerLink: 'social',
    //       iconType: 'letter',
    //       iconName: 'fe'
    //     },
    //     {
    //       label: 'Form Wizard',
    //       routerLink: 'forms/wizard',
    //       iconType: 'letter',
    //       iconName: 'fq'
    //     },
    //     {
    //       label: 'Builder',
    //       routerLink: 'builder/executive-builder',
    //       iconType: 'pg',
    //       iconName: 'layouts'
    //     },
    //     {
    //       label: 'Views',
    //       routerLink: 'views',
    //       iconType: 'pg',
    //       iconName: 'ui'
    //     }
    //   ]
    // },
    // {
    //   label: 'UI Elements',
    //   iconType: 'letter',
    //   iconName: 'Ui',
    //   toggle: 'close',
    //   mToggle: 'close',
    //   submenu: [
    //     {
    //       label: 'Color',
    //       routerLink: 'ui/color',
    //       iconType: 'letter',
    //       iconName: 'c'
    //     },
    //     {
    //       label: 'Typography',
    //       routerLink: 'ui/typography',
    //       iconType: 'letter',
    //       iconName: 't'
    //     },
    //     {
    //       label: 'Icons',
    //       routerLink: 'ui/icons',
    //       iconType: 'letter',
    //       iconName: 'i'
    //     },
    //     {
    //       label: 'Buttons',
    //       routerLink: 'ui/buttons',
    //       iconType: 'letter',
    //       iconName: 'b'
    //     },
    //     {
    //       label: 'Notifications',
    //       routerLink: 'ui/notifications',
    //       iconType: 'letter',
    //       iconName: 'n'
    //     },
        // {
        //   label: 'Modals',
        //   routerLink: 'ui/modal',
        //   iconType: 'letter',
        //   iconName: 'm'
        // },
    //     {
    //       label: 'Progress & Activity',
    //       routerLink: 'ui/progress',
    //       iconType: 'letter',
    //       iconName: 'pa'
    //     },
    //     {
    //       label: 'Tabs & Accordians',
    //       routerLink: 'ui/tabs',
    //       iconType: 'letter',
    //       iconName: 'a'
    //     },
    //     {
    //       label: 'Sliders',
    //       routerLink: 'ui/sliders',
    //       iconType: 'letter',
    //       iconName: 's'
    //     },
    //     {
    //       label: 'Treeview',
    //       routerLink: 'ui/tree',
    //       iconType: 'letter',
    //       iconName: 'tv'
    //     }
    //   ]
    // },
    // {
    //   label: 'Forms',
    //   iconType: 'pg',
    //   iconName: 'form',
    //   toggle: 'close',
    //   mToggle: 'close',
    //   submenu: [
    //     {
    //       label: 'Form Elements',
    //       routerLink: 'forms/elements',
    //       iconType: 'letter',
    //       iconName: 'fe'
    //     },
    //     {
    //       label: 'Form Layouts',
    //       routerLink: 'forms/layouts',
    //       iconType: 'letter',
    //       iconName: 'fl'
    //     },
    //     {
    //       label: 'Form Wizard',
    //       routerLink: 'forms/wizard',
    //       iconType: 'letter',
    //       iconName: 'fq'
    //     }
    //   ]
    // },
    // {
    //   label: 'Cards',
    //   routerLink: 'cards',
    //   iconType: 'pg',
    //   iconName: 'grid'
    // },
    // {
    //   label: 'Tables',
    //   iconType: 'pg',
    //   iconName: 'tables',
    //   toggle: 'close',
    //   mToggle: 'close',
    //   submenu: [
    //     {
    //       label: 'Basic Tables',
    //       routerLink: 'tables/basic',
    //       iconType: 'letter',
    //       iconName: 'bt'
    //     },
    //     {
    //       label: 'Advance Tables',
    //       routerLink: 'tables/advance',
    //       iconType: 'letter',
    //       iconName: 'dt'
    //     }
    //   ]
    // },
    // {
    //   label: 'Maps',
    //   iconType: 'pg',
    //   iconName: 'map',
    //   toggle: 'close',
    //   mToggle: 'close',
    //   submenu: [
    //     {
    //       label: 'Google Maps',
    //       routerLink: 'maps/google/with-header',
    //       iconType: 'letter',
    //       iconName: 'gm'
    //     }
    //   ]
    // },
    // {
    //   label: 'Charts',
    //   routerLink: 'charts',
    //   iconType: 'pg',
    //   iconName: 'charts'
    // },
    // {
    //   label: 'Docs',
    //   externalLink: 'https://docs.pages.revox.io/v/angular/',
    //   target: '_blank',
    //   iconType: 'pg',
    //   iconName: 'note'
    // },
    // {
    //   label: 'Changelog',
    //   externalLink: 'http://changelog.pages.revox.io/',
    //   target: '_blank',
    //   iconType: 'letter',
    //   iconName: 'Cl'
    // }
  ];
  ngOnInit() {
    pg.isHorizontalLayout = true;
    this.changeLayout('horizontal-menu');
    this.changeLayout('horizontal-app-menu');

    this.siteJson = SiteJson;
  }

  getRss() {
    window.open('rss.xml', '_blank');
  }

  getSitemap() {
    window.open('sitemap.xml', '_blank');
  }

  getFeed() {
    window.open('feed.xml', '_blank');
  }
}
