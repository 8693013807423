<div class="container-fluid bg-master-lighter ">
  <div class="timeline-container top-circle">
    <section class="timeline">
      <div class="timeline-block">
        <div class="timeline-point success">
          <i class="pg-icon">map</i>
        </div>
        <!-- timeline-point -->
        <div class="timeline-content">
          <div class="card social-card share full-width">
            <div class="circle" data-toggle="tooltip" title="Label" data-container="body">
            </div>
            <div class="card-header clearfix">
              <div class="user-pic">
                <img alt="Profile Image" width="33" height="33" src2x="assets/img/profiles/8x.jpg" pgRetina
                  src1x="assets/img/profiles/8.jpg" src="assets/img/profiles/8x.jpg">
              </div>
              <h5>Jeff Curtis</h5>
              <h6>Shared a Tweet
                <span class="location semi-bold"><i class="pg-icon">map</i> SF, California</span>
              </h6>
            </div>
            <div class="card-description">
              <p>What you think, you become. What you feel, you attract. What you imagine, you create - Buddha. <a
                  href="javascript:void(0)">#quote</a> </p>
              <div class="via">via Twitter</div>
            </div>
          </div>
          <div class="event-date">
            <h6 class="font-montserrat all-caps hint-text m-t-0">Apple Inc</h6>
            <small class="fs-12 hint-text">15 January 2015, 06:50 PM</small>
          </div>
        </div>
        <!-- timeline-content -->
      </div>
      <!-- timeline-block -->
      <div class="timeline-block">
        <div class="timeline-point small">
        </div>
        <!-- timeline-point -->
        <div class="timeline-content">
          <div class="card  social-card share full-width">
            <div class="circle" data-toggle="tooltip" title="Label" data-container="body">
            </div>
            <div class="card-header clearfix">
              <div class="user-pic">
                <img alt="Profile Image" width="33" height="33" src2x="assets/img/profiles/5x.jpg" pgRetina
                  src1x="assets/img/profiles/5.jpg" src="assets/img/profiles/5x.jpg">
              </div>
              <h5>Shannon Williams</h5>
              <h6>Shared a photo
                <span class="location semi-bold"><i class="pg-icon">map</i> NYC, New York</span>
              </h6>
            </div>
            <div class="card-description">
              <p>Inspired by : good design is obvious, great design is transparent</p>
              <div class="via">via themeforest</div>
            </div>
            <div class="card-content">
              <ul class="buttons ">
                <li>
                  <a href="javascript:void(0)"><i class="pg-icon">expand</i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)"><i class="pg-icon">heart</i>
                  </a>
                </li>
              </ul>
              <img alt="Social post" src="assets/img/social-post-image.png">
            </div>
            <div class="card-footer clearfix">
              <div class="time">few seconds ago</div>
              <ul class="reactions">
                <li><a href="javascript:void(0)" class="d-flex align-items-center">5,345 <i class="pg-icon">comment</i></a>
                </li>
                <li><a href="javascript:void(0)" class="d-flex align-items-center">23K <i class="pg-icon">heart</i></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="event-date">
            <small class="fs-12 hint-text">15 January 2015, 06:50 PM</small>
          </div>
        </div>
        <!-- timeline-content -->
      </div>
      <!-- timeline-block -->
      <div class="timeline-block">
        <div class="timeline-point warning">
          <i class="pg-icon">chat</i>
        </div>
        <!-- timeline-point -->
        <div class="timeline-content">
          <div class="card social-card share full-width ">
            <div class="card-header clearfix">
              <h5 class="text-warning pull-left fs-12">Stock Market <i class="pg-icon text-warning fs-11">circle</i>
              </h5>
              <div class="pull-right small hint-text d-flex align-items-center">
                5,345 <i class="pg-icon">comment</i>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="card-description">
              <h5 class='hint-text no-margin'>Apple Inc.</h5>
              <h5 class="small hint-text no-margin">NASDAQ: AAPL - Nov 13 8:37 AM ET</h5>
              <h3>111.25 <span class="text-warning"><i class="pg-icon text-warning">drop_up</i> 0.15 (0.13%)</span></h3>
            </div>
            <div class="card-footer clearfix">
              <div class="pull-left">by <span class="text-warning">John Smith</span></div>
              <div class="pull-right hint-text">
                Apr 23
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="event-date">
            <h6 class="font-montserrat all-caps hint-text m-t-0">Shared</h6>
            <small class="fs-12 hint-text">15 January 2015, 06:50 PM</small>
          </div>
        </div>
        <!-- timeline-content -->
      </div>
      <!-- timeline-block -->
      <div class="timeline-block">
        <div class="timeline-point small">
        </div>
        <!-- timeline-point -->
        <div class="timeline-content">
          <div class="card social-card share full-width ">
            <div class="circle" data-toggle="tooltip" title="Label" data-container="body">
            </div>
            <div class="card-header clearfix">
              <div class="user-pic">
                <img alt="Profile Image" width="33" height="33" src2x="assets/img/profiles/6x.jpg" pgRetina
                  src1x="assets/img/profiles/6.jpg" src="assets/img/profiles/6x.jpg">
              </div>
              <h5>Nathaniel Hamilton</h5>
              <h6>Shared a Tweet
                <span class="location semi-bold"><i class="icon-map"></i> NYC, New York</span>
              </h6>
            </div>
            <div class="card-description">
              <p>Testing can show the presense of bugs, but not their absence.</p>
              <div class="via">via Twitter</div>
            </div>
          </div>
          <div class="event-date">
            <small class="fs-12 hint-text">15 January 2015, 06:50 PM</small>
          </div>
        </div>
        <!-- timeline-content -->
      </div>
      <!-- timeline-block -->
      <div class="timeline-block">
        <div class="timeline-point small">
        </div>
        <!-- timeline-point -->
        <div class="timeline-content">
          <div class="card social-card share full-width">
            <div class="circle" data-toggle="tooltip" title="Label" data-container="body">
            </div>
            <div class="card-header clearfix">
              <div class="user-pic">
                <img alt="Profile Image" width="33" height="33" src2x="assets/img/profiles/4x.jpg" pgRetina
                  src1x="assets/img/profiles/4.jpg" src="assets/img/profiles/4x.jpg">
              </div>
              <h5>Andy Young</h5>
              <h6>Updated his status
                <span class="location semi-bold"><i class="icon-map"></i> NYC, New York</span>
              </h6>
            </div>
            <div class="card-description">
              <p>What a lovely day! I think I should go and play outside.</p>
            </div>
          </div>
          <div class="event-date">
            <small class="fs-12 hint-text">15 January 2015, 06:50 PM</small>
          </div>
        </div>
        <!-- timeline-content -->
      </div>
      <!-- timeline-block -->
    </section>
    <!-- timeline -->
  </div>
  <!-- -->
</div>
