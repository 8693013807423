import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'weekly-sales-widget',
  templateUrl: './weekly-sales-widget.component.html',
  styleUrls: ['./weekly-sales-widget.component.scss']
})
export class WeeklySalesWidgetComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
