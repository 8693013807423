<pgcard TimeOut="3000" AdditionalClasses="widget-7 card no-border bg-success no-margin full-height" MinimalHeader="true" BodyClass="no-padding" HeaderClass="hidden">
	<swiper [config]="config" [(index)]="index">
		<div class="slide-front tiles slide active">
			<div class="padding-25">
				<div class="pull-bottom padding-25 bottom-right bottom-left">
					<h5 class="no-margin semi-bold p-b-5">Apple Inc.</h5>

					<p class="no-margin hint-text text-ellipsis">NASDAQ : AAPL - NOV 018:40 AM ET</p>

					<h3 class="semi-bold"><i
							class="pg-icon">arrow_up</i> 0.15 (0.13%)
					</h3>

					<p>Open 
						<span class="m-l-20">117.52</span>
					</p>
				</div>
			</div>
		</div>
		<div class="slide-back tiles">
			<div class="container-sm-height full-height">
				<div class="row-sm-height">
					<div class="col-sm-height padding-25">
						<p class="">Pre-market: 116.850.50(0.43%)</p>

						<p class="p-t-25 fs-16 hint-text ">AAPL - Apple inc.</p>

						<div class="p-t-5 d-flex align-items-center">
							<p class="inline">+0.42% <span class="m-l-20">217.51</span>
							</p>
							<div class="inline"> <i class="pg-icon m-l-5">arrow_up</i>
							</div>
						</div>
						<p class="p-t-15 fs-16 hint-text ">GOOG - Google inc.</p>

						<div class="p-t-5 d-flex align-items-center">
							<p class="inline">+0.22% <span class="m-l-20">-2.28</span>
							</p>

							<div class="inline"> <i class="pg-icon m-l-5">arrow_down</i>
							</div>
						</div>
					</div>
				</div>
				<div class="row-sm-height">
					<div class="col-sm-height relative">
						<div class='widget-7-chart line-chart' data-line-color="white"
							 data-points="true" data-point-color="white"
							 data-stroke-width="3">
							<svg></svg>
						</div>
					</div>
				</div>
			</div>
		</div>
	</swiper>
</pgcard>