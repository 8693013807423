import {
  Component,
  OnInit,
  HostBinding,
  Input,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  TemplateRef,
  ContentChild,
  EventEmitter,
  Output
} from '@angular/core';

@Component({
  selector: 'pgcardbook',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './card-book.component.html'
})
export class pgCardBook implements OnInit {
  name = '';
  author = '';
  coverUrl = '';
  title = '';
  titleClass = 'text-complete';
  type = 'text';
  comments = '';
  likes = '';
  body = '';
  timestamp = '';
  source = '';
  image = '';
  activity = '';
  location = '';
  additionalClasses = '';

  @ViewChild('hostContent') _hostContent: ElementRef;
  @ContentChild('CustomBody', { static: true }) CustomBody: TemplateRef<void>;
  @ContentChild('AuthorAvatar', { static: true }) AuthorAvatar: TemplateRef<
    void
  >;

  @HostBinding('class') classList = '';
  @Input() class: string;

  @Input()
  set Name(value: string) {
    this.name = value;
  }

  @Input()
  set Author(value: string) {
    this.author = value;
  }

  @Input()
  set CoverUrl(value: string) {
    this.coverUrl = value;
  }

  @Input()
  set Title(value: string) {
    this.title = value;
  }

  @Input()
  set TitleClass(value: string) {
    this.titleClass = value;
  }

  @Input()
  set Type(value: string) {
    this.type = value;
  }

  @Input()
  set Comments(value: string) {
    this.comments = value;
  }

  @Input()
  set Likes(value: string) {
    this.likes = value;
  }

  @Input()
  set Body(value: string) {
    this.body = value;
  }

  @Input()
  set Timestamp(value: string) {
    this.timestamp = value;
  }

  @Input()
  set Source(value: string) {
    this.source = value;
  }

  @Input()
  set Activity(value: string) {
    this.activity = value;
  }

  @Input()
  set Image(value: string) {
    this.image = value;
    console.log(value);
  }

  @Input()
  set Location(value: string) {
    this.location = value;
  }

  @Input()
  set AdditionalClasses(value: string) {
    this.additionalClasses = value;
  }

  ngOnInit() {
    if (this.class) {
      this.classList += ' ' + this.class;
    }
  }
}
