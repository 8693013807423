import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Blog } from 'app/blog/blog';
import { Post } from 'app/blog/post';
import SiteJson from 'assets/data/site.json';
import { environment } from 'environments/environment.prod';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})

export class PostComponentPage implements OnInit {
  post: Post = new Post();
  siteJson;
  accessToken;

  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit() {
    this.getPostFromApi(this.route.snapshot.paramMap.get('permalink'));
    this.siteJson = SiteJson;
  }

  getPostFromApi(permalink: string) {
    return this.http.get<Post>(environment.apiUrl + '/writings/' + permalink, {

    }).subscribe(data => {
      this.post = data;
    });
  }

  private getToken() {
    this.http.post<any>(environment.apiUrl + '/security/get-token', {
      username: 'oguzhankircali',
      password: 'Qwe.123!api'
    }).subscribe(data => {
      this.accessToken = data.token;

      this.getPostFromApi(this.route.snapshot.paramMap.get('permalink'));
    });
  }
}
