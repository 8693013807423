import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import ResumeJson from 'assets/data/resume.json';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {

  resumeJson;

  constructor(private route: ActivatedRoute) { //, private _notification: MessageService

  }

  ngOnInit() {

    this.resumeJson = ResumeJson;
  }
}
