<pgcard TimeOut="3000" AdditionalClasses="card no-border widget-loader-bar m-b-10" Maximize="false" Toggle="false"
	Close="false" Controls="false" BodyClass="no-padding" HeaderClass="top-left top-right">
	<ng-template #CardTitle>
		<span class="font-montserrat fs-11 all-caps">
			Page Visits
			<i class="fa fa-chevron-right"></i>
		</span>
	</ng-template>
	<div class="container-xs-height full-height">
		<div class="row-xs-height">
			<div class="col-xs-height col-top">
				<div class="p-l-20 p-t-50 p-b-40 p-r-20">
					<h3 class="no-margin p-b-5">423</h3>
					<span class="small hint-text pull-left">22% higher</span>
					<span class="pull-right small text-danger">$23,000</span>
				</div>
			</div>
		</div>
		<div class="row-xs-height">
			<div class="col-xs-height col-bottom">
				<div class="progress progress-small m-b-0">
					<!-- START BOOTSTRAP PROGRESS (http://getbootstrap.com/components/#progress) -->
					<div class="progress-bar progress-bar-danger" style="width:15%"></div>
					<!-- END BOOTSTRAP PROGRESS -->
				</div>
			</div>
		</div>
	</div>
</pgcard>