<pgcard TimeOut="3000" AdditionalClasses="widget-13 card no-border  no-margin widget-loader-circle" Maximize="false"
  Toggle="false" Close="false" BodyClass="no-padding" HeaderClass="top-right">
  <div class="container-sm-height no-overflow">
    <div class="row row-sm-height">
      <div class="col-sm-5 col-lg-4 col-xlg-3 col-sm-height col-top no-padding">
        <div class="card-header  ">
          <div class="card-title">
            <span class="font-montserrat fs-11 all-caps">
              Geolocation
            </span>
          </div>
        </div>
        <div class="card-body">
          <ul class="nav nav-pills m-t-5 m-b-15" role="tablist">
            <li class="active">
              <a href="javascript:void(0)" (click)="snapToCountry('United States')" class="b-a b-grey text-color">
                fb
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" (click)="snapToCountry('Canada')" class="b-a b-grey text-color">
                js
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" (click)="snapToCountry('Greenland')" class="b-a b-grey text-color">
                sa
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane active" id="tab1">
              <h3>Facebook</h3>
              <p class="hint-text all-caps font-montserrat small no-margin ">Views</p>
              <p class="all-caps font-montserrat  no-margin text-success ">14,256</p>
              <br>
              <p class="hint-text all-caps font-montserrat small no-margin ">Today</p>
              <p class="all-caps font-montserrat  no-margin text-warning ">24</p>
              <br>
              <p class="hint-text all-caps font-montserrat small no-margin ">Week</p>
              <p class="all-caps font-montserrat  no-margin text-success ">56</p>
            </div>
            <div class="tab-pane " id="tab2">
              <h3>Google</h3>
              <p class="hint-text all-caps font-montserrat small no-margin ">Views</p>
              <p class="all-caps font-montserrat  no-margin text-success ">14,256</p>
              <br>
              <p class="hint-text all-caps font-montserrat small no-margin ">Today</p>
              <p class="all-caps font-montserrat  no-margin text-warning ">24</p>
              <br>
              <p class="hint-text all-caps font-montserrat small no-margin ">Week</p>
              <p class="all-caps font-montserrat  no-margin text-success ">56</p>
            </div>
            <div class="tab-pane" id="tab3">
              <h3>Amazon</h3>
              <p class="hint-text all-caps font-montserrat small no-margin ">Views</p>
              <p class="all-caps font-montserrat  no-margin text-success ">14,256</p>
              <br>
              <p class="hint-text all-caps font-montserrat small no-margin ">Today</p>
              <p class="all-caps font-montserrat  no-margin text-warning ">24</p>
              <br>
              <p class="hint-text all-caps font-montserrat small no-margin ">Week</p>
              <p class="all-caps font-montserrat  no-margin text-success ">56</p>
            </div>
          </div>
        </div>
        <div class="bg-master-lighter p-l-20 p-r-20 p-t-10 p-b-10 pull-bottom full-width hidden-xs">
          <p class="no-margin">
            <a href="#" class="btn-circle-arrow b-grey"><i class="pg-icon">chevron_down</i></a>
            <span class="hint-text">Super secret options</span>
          </p>
        </div>
      </div>
      <div class="col-sm-7 col-lg-8 col-xlg-9 col-sm-height col-top no-padding relative">
        <div class="widget-13-map" [class.bg-success]="!_isGrayscale" [class.bg-complete]="_isGrayscale"
          #chartContainer>
          <div #chart class="full-width full-height" (window:resize)="onResize($event)"></div>
          <div echarts [options]="chartOptions"  [merge]="mapUpdate" [initOpts]="chartInitOptions" (chartInit)="onChartInit($event)" (window:resize)="onResize($event)"></div>				
        </div>
      </div>
    </div>
  </div>
</pgcard>
