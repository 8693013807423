import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '@pages/components/message/message.service';
import ResumeJson from 'assets/data/resume.json';
// import { NgsRevealConfig } from 'ng-scrollreveal';
declare var pg: any;
@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {
  // Config Docs
  // https://tinesoft.github.io/ng-scrollreveal/doc/injectables/NgsRevealConfig.html

  resumeJson;

  notificationModel: any = {
    type: 'circle',
    message: 'This notification looks so perfect!',
    color: 'info',
    position: 'top',
    current: 0
  };
  colors = [
    { value: 'info', label: 'Info' },
    { value: 'warning', label: 'Warning' },
    { value: 'success', label: 'Success' },
    { value: 'danger', label: 'Danger' },
    { value: 'default', label: 'Default' }
  ];
  selectedColor;
  currentTab = 0;

  nofitcationStrings: any = [
    {
      heading: 'Notification Bar',
      desc: 'Awesome Loading Circle Animation',
      position: 'top',
      type: 'bar'
    },
    {
      heading: 'Flip Bar',
      desc: 'Awesome Loading Circle Animation',
      position: 'top-right',
      type: 'flip'
    },
    {
      heading: 'Circle Notification',
      desc: 'Awesome Loading Circle Animation',
      position: 'top-right',
      type: 'circle',
      title: 'John Doe',
      imgURL: 'assets/img/profiles/avatar.jpg'
    },
    {
      heading: 'Simple Alert',
      desc: 'Awesome Loading Circle Animation',
      position: 'top-right',
      type: 'simple'
    }
  ];

  constructor(private route: ActivatedRoute) { //, private _notification: MessageService
    // Only for demo - Casual and Executive layout
    this.route.params.subscribe(params => {
      // if (params['type'] === 'container') {
      //   config.container = document.querySelector('.page-container');
      // }
      // config.distance = '0';
    });
  }

  ngOnInit() {

    this.resumeJson = ResumeJson;
  }

  // createBasicNotification() {
  //   this._notification.create(this.notificationModel.color, this.notificationModel.message, {
  //     Title: 'Oguzhan:',
  //     imgURL: 'assets/img/profiles/avatar_small2x.jpg',
  //     Position: 'top-right',
  //     Style: this.notificationModel.type,
  //     Duration: 0
  //   });
  // }
}
