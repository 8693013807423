<pgcard AdditionalClasses="widget-18 card no-border  no-margin " HeaderClass="hidden" BodyClass="no-padding">
	<div class="padding-15">
		<div class="item-header clearfix">
			<div class="thumbnail-wrapper d32 circular">
				<img width="40" height="40" src="assets/img/profiles/3x.jpg"
						 data-src="assets/img/profiles/3.jpg" data-src-retina="/assets/img/profiles/3x.jpg"
						 alt="">
			</div>
			<div class="inline m-l-10">
				<p class="no-margin">
					<strong>Anne Simons</strong>
				</p>

				<p class="no-margin hint-text">Shared a link
					<span class="location semi-bold"><i class="pg-icon">map</i> NY center</span>
				</p>
			</div>
		</div>
	</div>
	<div class="relative">
		<ul class="buttons pull-top top-right list-inline p-r-10 p-t-10">
			<li>
				<a class="text-white" href="javascript:void(0)"><i class="pg-icon">expand</i>
				</a>
			</li>
			<li>
				<a class="text-white" href="javascript:void(0)"><i class="pg-icon">heart_outline</i>
				</a>
			</li>
		</ul>
		<div class="widget-18-post bg-black no-overflow">
		</div>
	</div>
	<div class="padding-15">
		<div class="hint-text pull-left fs-13">few seconds ago</div>
		<ul class="list-inline pull-right no-margin">
			<li><a class="text-color hint-text fs-13 d-flex" href="#"><i class="pg-icon m-r-5">comment_alt</i> 5,345</a>
			</li>
			<li><a class="text-color hint-text fs-13 d-flex" href="#"><i class="pg-icon m-r-5">heart</i> 23K</a>
			</li>
		</ul>
		<div class="clearfix"></div>
	</div>
</pgcard>