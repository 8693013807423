<pgcard TimeOut="3000" AdditionalClasses="card no-border  no-margin widget-loader-circle todolist-widget align-self-stretch"
 Maximize="false" Toggle="false" Close="false" Controls="false" BodyClass="no-padding d-flex flex-column">
	<ng-template #CardTitle>
		<span class="font-montserrat fs-11 all-caps">
			TODOLIST
		</span>
	</ng-template>
	<ul class="list-unstyled p-l-20 p-r-20 p-t-10 m-b-20">
		<li>
			<h5 class="pull-left normal no-margin">28th September</h5>
			<a href="javascript:void(0)" class="text-black pull-right m-l-5" data-toggle="refresh">
				<i class="fa fa-angle-right"></i>
			</a>
			<a href="javascript:void(0)" class="text-black pull-right m-r-5" data-toggle="refresh">
				<i class="fa fa-angle-left"></i>
			</a>

		</li>
		<div class="clearfix"></div>
	</ul>
	<div class="task-list p-t-0 p-r-20 p-b-20 p-l-20 clearfix flex-1">
		<!-- START TAKS !-->
		<div class="task clearfix row completed">
			<div class="task-list-title col-10 justify-content-between">
				<a href="javascript:void(0)" class="text-color strikethrough" data-task="name">Purchase Pages before 10am
				</a>
				<i class="fs-14 pg pg-close hidden"></i>
			</div>
			<div class="form-check checkbox-circle no-margin text-center col-2 d-flex justify-content-center align-items-center">
				<input type="checkbox" checked="checked" value="1" id="todocheckbox" data-toggler="task" class="hidden">
				<label for="todocheckbox" class=" no-margin no-padding absolute"></label>
			</div>
		</div>
		<!-- END TAKS !-->
		<!-- START TAKS !-->
		<div class="task clearfix row">
			<div class="task-list-title col-10 justify-content-between">
				<a href="javascript:void(0)" class="text-color" data-task="name">Meeting with CFO
				</a>
				<i class="fs-14 pg pg-close hidden"></i>
			</div>
			<div class="form-check checkbox-circle no-margin text-center col-2 d-flex justify-content-center align-items-center">
				<input type="checkbox" value="1" id="todocheck2" data-toggler="task" class="hidden">
				<label for="todocheck2" class=" no-margin no-padding absolute"></label>
			</div>
		</div>
		<!-- END TAKS !-->
		<!-- START TAKS !-->
		<div class="task clearfix row">
			<div class="task-list-title col-10 justify-content-between">
				<a href="javascript:void(0)" class="text-color" data-task="name">AGM Conference at 1pm
				</a>
				<i class="fs-14 pg pg-close hidden"></i>
			</div>
			<div class="form-check checkbox-circle no-margin text-center col-2 d-flex justify-content-center align-items-center">
				<input type="checkbox" value="1" id="todocheck3" data-toggler="task" class="hidden">
				<label for="todocheck3" class=" no-margin no-padding absolute"></label>
			</div>
		</div>
		<!-- END TAKS !-->
		<!-- START TAKS !-->
		<div class="task clearfix row">
			<div class="task-list-title col-10 justify-content-between">
				<a href="javascript:void(0)" class="text-color" data-task="name">Revise Annual Reports
				</a>
				<i class="fs-14 pg pg-close hidden"></i>
			</div>
			<div class="form-check checkbox-circle no-margin text-center col-2 d-flex justify-content-center align-items-center">
				<input type="checkbox" value="1" id="todocheck4" data-toggler="task" class="hidden">
				<label for="todocheck4" class=" no-margin no-padding absolute"></label>
			</div>
		</div>
		<!-- END TAKS !-->
	</div>
	<div class="clearfix"></div>
	<div class="bg-master-light padding-20 full-width ">
		<div class="row">
			<div class="col-10">
				<p class="no-margin normal text-black">Type Event Here</p>
				<div class="input-group transparent no-border full-width">
					<input class="form-control transparent p-l-0" type="text" placeholder="What do you need to remeber?">
				</div>
			</div>
			<div class="col-2 text-center">
				<a href="javascript:void(0)" class="block m-t-15">
					<img src="assets/img/plus.svg">
				</a>
			</div>
		</div>
	</div>
</pgcard>
