export const stackedAreaData = [
    {
        "key": "North America",
        "values": [
            23.041422681023,
            19.854291255832,
            21.02286281168,
            22.093608385173,
            25.108079299458,
            26.982389242348,
            19.828984957662,
            19.914055036294,
            19.436150539916,
            21.558650338602,
            24.395594061773,
            24.747089309384,
            23.491755498807,
            23.376634878164,
            24.581223154533,
            24.922476843538,
            27.357712939042,
            26.503020572593,
            26.658901244878,
            27.065704156445,
            28.735320452588,
            31.572277846319,
            30.932161503638,
            31.627029785554,
            28.728743674232,
            26.858365172675,
            27.279922830032,
            34.408301211324,
            34.794362930439,
            35.609978198951,
            33.574394968037,
            31.979405070598,
            31.19009040297,
            31.083933968994,
            29.668971113185,
            31.490638014379,
            31.818617451128,
            32.960314008183,
            31.313383196209,
            33.125486081852,
            32.791805509149,
            33.506038030366,
            26.96501697216,
            27.38478809681,
            27.371377218209,
            26.309915460827,
            26.425199957518,
            26.823411519396,
            23.850443591587,
            23.158355444054,
            22.998689393695,
            27.9771285113,
            29.073672469719,
            28.587640408904,
            22.788453687637,
            22.429199073597,
            22.324103271052,
            17.558388444187,
            16.769518096208,
            16.214738201301,
            18.729632971229,
            18.814523318847,
            19.789986451358,
            17.070049054933,
            16.121349575716,
            15.141659430091,
            17.175388025297,
            17.286592443522,
            16.323141626568,
            19.231263773952,
            18.446256391095,
            17.822632399764,
            15.53936647598,
            15.255131790217,
            15.660963922592,
            13.254482273698,
            11.920796202299,
            12.122809090924,
            15.691026271393,
            14.720881635107,
            15.387939360044,
            13.765436672228,
            14.631445864799,
            14.292446536221,
            16.170071367017,
            15.948135554337,
            16.612872685134,
            18.778338719091,
            16.756026065421,
            19.385804443146,
            22.950590240168,
            23.61159018141,
            25.708586989581,
            26.883915999885,
            25.893486687065,
            24.678914263176,
            25.937275793024,
            29.461381693838,
            27.357322961861,
            29.057235285673,
            28.549434189386,
            28.506352379724,
            29.449241421598,
            25.796838168807,
            28.740145449188,
            22.091744141872,
            25.07966254541,
            23.674906973064,
            23.418002742929,
            23.24364413887,
            31.591854066817,
            31.497112374114,
            26.67238082043,
            27.297080015495,
            20.174315530051,
            19.631084213898,
            20.366462219461,
            19.284784434185,
            19.157810257624
        ]
    },
    {
        "key": "Africa",
        "values": [
            7.9356392949025,
            7.4514668527298,
            7.9085410566608,
            5.8996782364764,
            6.0591869346923,
            5.9667815800451,
            8.65528925664,
            8.7690763386254,
            8.6386160387453,
            5.9895557449743,
            6.3840324338159,
            6.5196511461441,
            7.0738618553114,
            6.5745957367133,
            6.4658359184444,
            2.7622758754954,
            2.9794782986241,
            2.8735432712019,
            1.6344817513645,
            1.5869248754883,
            1.7172279157246,
            1.9649927409867,
            2.0261695079196,
            2.0541261923929,
            3.9466318927569,
            3.7826770946089,
            3.9543021004028,
            3.8309891064711,
            3.6340958946166,
            3.5289755762525,
            5.702378559857,
            5.6539569019223,
            5.5449506370392,
            4.7579993280677,
            4.4816139372906,
            4.5965558568606,
            4.3747066116976,
            4.4588822917087,
            4.4460351848286,
            3.7989113035136,
            3.7743883140088,
            3.7727852823828,
            7.2968111448895,
            7.2800122043237,
            7.1187787503354,
            8.351887016482,
            8.4156698763993,
            8.1673298604231,
            5.5132447126042,
            6.1152537710599,
            6.076765091942,
            4.6304473798646,
            4.6301068469402,
            4.3466656309389,
            6.830104897003,
            7.241633040029,
            7.1432372054153,
            10.608942063374,
            10.914964549494,
            10.933223880565,
            8.3457524851265,
            8.1078413081882,
            8.2697185922474,
            8.4742436475968,
            8.4994601179319,
            8.7387319683243,
            6.8829183612895,
            6.984133637885,
            7.0860136043287,
            4.3961787956053,
            3.8699674365231,
            3.6928925238305,
            6.7571718894253,
            6.4367313362344,
            6.4048441521454,
            5.4643833239669,
            5.3150786833374,
            5.3011272612576,
            4.1203601430809,
            4.0881783200525,
            4.1928665957189,
            7.0249415663205,
            7.006530880769,
            6.994835633224,
            6.1220222336254,
            6.1177436137653,
            6.1413396231981,
            4.8046006145874,
            4.6647600660544,
            4.544865006255,
            6.0488249316539,
            6.3188669540206,
            6.5873958262306,
            6.2281189839578,
            5.8948915746059,
            5.5967320482214,
            0.99784432084837,
            1.0950794175359,
            0.94479734407491,
            1.222093988688,
            1.335093106856,
            1.3302565104985,
            1.340824670897,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            4.4583692315,
            3.6493043348059,
            3.8610064091761,
            5.5144800685202,
            5.1750695220791,
            5.6710066952691,
            5.5611890039181,
            5.5979368839939
        ]
    },

    {
        "key": "South America",
        "values": [
            7.9149900245423,
            7.0899888751059,
            7.5996132380614,
            8.2741174301034,
            9.3564460833513,
            9.7066786059904,
            10.213363052343,
            10.285809585273,
            10.222053149228,
            8.6188592137975,
            9.3335447543566,
            8.9312402186628,
            8.1895089343658,
            8.260622135079,
            7.7700786851364,
            7.9907428771318,
            8.7769091865606,
            8.4855077060661,
            9.6277203033655,
            9.9685913452624,
            10.615085181759,
            9.2902488079646,
            8.8610439830061,
            9.1075344931229,
            9.9156737639203,
            9.7826003238782,
            10.55403610555,
            10.926900264097,
            10.903144818736,
            10.862890389067,
            10.64604998964,
            10.042790814087,
            9.7173391591038,
            9.6122415755443,
            9.4337921146562,
            9.814827171183,
            12.059260396788,
            12.139649903873,
            12.281290663822,
            8.8037085409056,
            8.6300618239176,
            9.1225708491432,
            12.988124170836,
            13.356778764353,
            13.611196863271,
            6.8959030061189,
            6.9939633271353,
            6.7241510257676,
            5.5611293669517,
            5.6086488714041,
            5.4962849907033,
            6.9193153169278,
            7.0016334389778,
            6.7865422443273,
            9.0006454225383,
            9.2233916171431,
            8.8929316009479,
            10.345937520404,
            10.075914677026,
            10.089006188111,
            10.598330295008,
            9.9689546533009,
            9.7740580198146,
            10.558483060626,
            9.9314651823603,
            9.3997715873769,
            8.4086493387262,
            8.9698309085926,
            8.2778357995396,
            8.8585045600123,
            8.7013756413322,
            7.7933605469443,
            7.0236183483064,
            6.9873088186829,
            6.8031713070097,
            6.6869531315723,
            6.138256993963,
            5.6434994016354,
            5.495220262512,
            4.6885326869846,
            4.4524349883438,
            5.6766520778185,
            5.7675774480752,
            5.7882863168337,
            7.2666010034924,
            7.5191821322261,
            7.849651451445,
            10.383992037985,
            9.0653691861818,
            9.6705248324159,
            10.856380561349,
            11.27452370892,
            11.754156529088,
            8.2870811422455,
            8.0210264360699,
            7.5375074474865,
            8.3419527338039,
            9.4197471818443,
            8.7321733185797,
            9.6627062648126,
            10.187962234548,
            9.8144201733476,
            10.275723361712,
            16.796066079353,
            17.543254984075,
            16.673660675083,
            17.963944353609,
            16.63774086721,
            15.84857094609,
            14.767303362181,
            24.778452182433,
            18.370353229999,
            15.253137429099,
            14.989600840649,
            16.052539160125,
            16.424390322793,
            17.884020741104,
            18.372698836036,
            18.315881576096
        ]
    },

    {
        "key": "Asia",
        "values": [
            13.153938631352,
            12.456410521864,
            12.537048663919,
            13.947386398309,
            14.421680682568,
            14.143238262286,
            12.229635347478,
            12.508479916948,
            12.155368409526,
            13.335455563994,
            12.888210138167,
            12.842092790511,
            12.513816474199,
            12.21453674494,
            11.750848343935,
            10.526579636787,
            10.873596086087,
            11.019967131519,
            11.235789380602,
            11.859910850657,
            12.531031616536,
            11.360451067019,
            11.456244780202,
            11.436991407309,
            11.638595744327,
            11.190418301469,
            11.835608007589,
            11.540980244475,
            10.958762325687,
            10.885791159509,
            13.605810720109,
            13.128978067437,
            13.119012086882,
            13.003706129783,
            13.326996807689,
            13.547947991743,
            12.807959646616,
            12.931763821068,
            12.795359993008,
            9.6998935538319,
            9.3473740089131,
            9.36902067716,
            14.258619539875,
            14.21241095603,
            13.973193618249,
            15.218233920664,
            14.382109727451,
            13.894310878491,
            15.593086090031,
            16.244839695189,
            16.017088850647,
            14.183951830057,
            14.148523245696,
            13.424326059971,
            12.974450435754,
            13.232470418021,
            13.318762655574,
            15.961407746104,
            16.287714639805,
            16.24659058389,
            17.564505594808,
            17.872725373164,
            18.018998508756,
            15.584518016602,
            15.480850647182,
            15.699120036985,
            19.184281817226,
            19.691226605205,
            18.982314051293,
            18.707820309008,
            17.459630929759,
            16.500616076782,
            18.086324003978,
            18.929464156259,
            18.233728682084,
            16.315776297325,
            14.632892190251,
            14.667835024479,
            13.946993947309,
            14.394304684398,
            13.724462792967,
            10.930879035807,
            9.8339915513708,
            10.053858541872,
            11.786998438286,
            11.780994901769,
            11.305889670277,
            10.918452290083,
            9.6811395055706,
            10.971529744038,
            13.330210480209,
            14.592637568961,
            14.605329141157,
            13.936853794037,
            12.189480759072,
            11.676151385046,
            13.058852800018,
            13.62891543203,
            13.811107569918,
            13.786494560786,
            14.045162857531,
            13.697412447286,
            13.677681376221,
            19.96151186453,
            21.049198298156,
            22.687631094009,
            25.469010617433,
            24.88379943712,
            24.203843814249,
            22.138760964036,
            16.034636966228,
            15.394958944555,
            12.62564246197,
            12.973735699739,
            15.78601833615,
            15.227368020134,
            15.899752650733,
            15.661317319168,
            15.359891177281
        ]
    },

    {
        "key": "Europe",
        "values": [
            9.3433263069351,
            8.4583069475546,
            8.0342398154196,
            8.1538966876572,
            10.743604786849,
            12.349366155851,
            10.742682503899,
            11.360983869935,
            11.441336039535,
            10.897508791837,
            11.469101547709,
            12.086311476742,
            8.0697180773504,
            8.2004392233445,
            8.4566434900643,
            7.9565760979059,
            9.3764619255827,
            9.0747664160538,
            10.508939004673,
            10.69936754483,
            10.681562399145,
            13.184786109406,
            12.668213052351,
            13.430509403986,
            12.393086349213,
            11.942374044842,
            12.062227685742,
            11.969974363623,
            12.14374574055,
            12.69422821995,
            9.1235211044692,
            8.758211757584,
            8.8072309258443,
            11.687595946835,
            11.079723082664,
            12.049712896076,
            10.725319428684,
            10.844849996286,
            10.833535488461,
            17.180932407865,
            15.894764896516,
            16.412751299498,
            12.573569093402,
            13.242301508051,
            12.863536342041,
            21.034044171629,
            21.419084618802,
            21.142678863692,
            26.56848967753,
            24.839144939906,
            25.456187462166,
            26.350164502825,
            26.478333205189,
            26.425979547846,
            28.191461582256,
            28.930307448808,
            29.521413891117,
            28.188285966466,
            27.704619625831,
            27.49086242483,
            28.770679721286,
            29.06048067145,
            28.240998844973,
            33.004893194128,
            34.075180359928,
            32.548560664834,
            30.629727432729,
            28.642858788159,
            27.973575227843,
            27.393351882726,
            28.476095288522,
            29.29667866426,
            29.222333802896,
            28.092966093842,
            28.107159262922,
            25.482974832099,
            21.208115993834,
            20.295043095268,
            15.925754618402,
            17.162864628346,
            17.084345773174,
            22.24600710228,
            24.530543998508,
            25.084184918241,
            16.606166527359,
            17.239620011628,
            17.336739127379,
            25.478492475754,
            23.017152085244,
            25.617745423684,
            24.061133998641,
            23.223933318646,
            24.425887263936,
            25.501471156693,
            23.775013878675,
            20.417993630285,
            20.023598978467,
            23.327519522436,
            21.963388450372,
            20.49896723209,
            22.403696817913,
            21.47736071922,
            21.53259666241,
            21.760282761548,
            25.605771243237,
            29.986557966215,
            23.84633051005,
            29.857316881858,
            27.675127768207,
            25.775077970313,
            28.631009702578,
            22.830831754505,
            25.611502589362,
            25.320136981738,
            21.564136901516,
            22.074407502433,
            25.053013769977,
            23.873085184128,
            32.321039427046
        ]
    },

    {
        "key": "Australia",
        "values": [
            5.1162447683392,
            4.2022848306513,
            4.3543715758736,
            5.4641223667245,
            6.0041275884577,
            6.6050520064486,
            5.0154059912793,
            5.1835708554647,
            5.1142682006164,
            5.0271381717695,
            5.3437782653456,
            5.2105844515767,
            6.552565997799,
            6.9873363581831,
            7.010986789097,
            4.4254242025515,
            4.9613848042174,
            4.8854920484764,
            4.0441111794228,
            4.0219596813179,
            4.3065749225355,
            3.9148434915404,
            3.8659430654512,
            3.9572824600686,
            4.7372190641522,
            4.6871476374455,
            5.0398702564196,
            5.5221787544964,
            5.424646299798,
            5.9240223067349,
            5.9936860983601,
            5.8499523215019,
            6.4149040329325,
            6.4547895561969,
            5.9385382611161,
            6.0486751030592,
            5.23108613838,
            5.5857797121029,
            5.3454665096987,
            5.0439154120119,
            5.054634702913,
            5.3819451380848,
            5.2638869269803,
            5.5806167415681,
            5.4539047069985,
            7.6728842432362,
            7.719946716654,
            8.0144619912942,
            7.942223133434,
            8.3998279827444,
            8.532324572605,
            4.7324285199763,
            4.7402397487697,
            4.9042069355168,
            5.9583963430882,
            6.3693899239171,
            6.261153903813,
            5.3443942184584,
            5.4932111235361,
            5.5747393101109,
            5.3833633060013,
            5.5125898831832,
            5.8116112661327,
            4.3962296939996,
            4.6967663605521,
            4.7963004350914,
            4.1817985183351,
            4.3797643870182,
            4.6966642197965,
            4.3609995132565,
            4.4736290996496,
            4.3749762738128,
            3.3274661194507,
            3.0316184691337,
            2.5718140204728,
            2.7034994044603,
            2.2033786591364,
            1.9850621240805,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0.44495950017788,
            0.33945469262483,
            0.38348269455195,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0.52216435716176,
            0.59275786698454,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ]
    },

    {
        "key": "Antarctica",
        "values": [
            1.3503144674343,
            1.2232741112434,
            1.3930470790784,
            1.2631275030593,
            1.5842699103708,
            1.9546996043116,
            0.8504048300986,
            0.85340686311353,
            0.843061357391,
            2.119846992476,
            2.5285382124858,
            2.5056570712835,
            2.5212789901005,
            2.6192011642534,
            2.5382187823805,
            2.3393223047168,
            2.491219888698,
            2.497555874906,
            1.734018115546,
            1.9307268299646,
            2.2261679836799,
            1.7608893704206,
            1.6242690616808,
            1.7161663801295,
            1.7183554537038,
            1.7179780759145,
            1.7314274801784,
            1.2596883356752,
            1.381177053009,
            1.4408819615814,
            3.4743581836444,
            3.3603749903192,
            3.5350883257893,
            3.0949644237828,
            3.0796455899995,
            3.3441247640644,
            4.0947643978168,
            4.4072631274052,
            4.4870979780825,
            4.8404549457934,
            4.8293016233697,
            5.2238093263952,
            3.382306337815,
            3.7056975170243,
            3.7561118692318,
            2.861913700854,
            2.9933744103381,
            2.7127537218463,
            3.1195497076283,
            3.4066964004508,
            3.3754571113569,
            2.2965579982924,
            2.4486818633018,
            2.4002308848517,
            1.9649579750349,
            1.9385263638056,
            1.9128975336387,
            2.3412869836298,
            2.4337870351445,
            2.62179703171,
            3.2642864957929,
            3.3200396223709,
            3.3934212707572,
            4.2822327088179,
            4.1474964228541,
            4.1477082879801,
            5.2947122916128,
            5.2919843508028,
            5.198978305031,
            3.5603057673513,
            3.3009087690692,
            3.1784852603792,
            4.5889503538868,
            4.401779617494,
            4.2208301828278,
            3.89396671475,
            3.0423832241354,
            3.135520611578,
            1.9631418164089,
            1.8963543874958,
            1.8266636017025,
            0.93136635895188,
            0.92737801918888,
            0.97591889805002,
            2.6841193805515,
            2.5664341140531,
            2.3887523699873,
            1.1737801663681,
            1.0953582317281,
            1.2495674976653,
            0.36607452464754,
            0.3548719047291,
            0.36769242398939,
            0,
            0,
            0,
            0,
            0,
            0,
            0.85450741275337,
            0.91360317921637,
            0.89647678692269,
            0.87800687192639,
            0,
            0,
            0.43668720882994,
            0.4756523602692,
            0.46947368328469,
            0.45138896152316,
            0.43828726648117,
            2.0820861395316,
            0.9364411075395,
            0.60583907839773,
            0.61096950747437,
            0,
            0,
            0,
            0,
            0
        ]
    }
];

export const lineAreaData = [
	{
	  key: "6 month sale X",
	  values: [
		"11/22/2014",
		"11/22/2014",
		"11/24/2014",
		"11/25/2014",
		"11/26/2014",
		"11/27/2014",
		"11/28/2014",
		"11/29/2014",
		"11/30/2014",
	  ],
	},
	{
	  key: "6 month sale Y",
	  values: [0, 10, 20, 20, 30, 20, 10, 30, 15],
	},
	{
	  key: "chart one X",
	  values: [1, 2, 3, 4, 5, 6, 7, 8],
	},
	{
	  key: "chart one Y",
	  values: [0, 20, 28, 20, 15, 20, 15, 35],
	},
	{
	  key: "chart two X",
	  values: [1, 2, 3, 4, 5, 6, 7, 8],
	},
	{
	  key: "chart two Y",
	  values: [30, 0, 25, 29, 20, 22, 20, 42],
	},
  ];
  