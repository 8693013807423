<!-- START CONTAINER FLUID -->
<div class="container-fluid padding-25 sm-padding-10 sm-gutter">
  <!-- START ROW -->
  <div class="row">
    <div class="col-lg-6 col-xlg-5">
      <div class="row">
        <div class="col-md-12 m-b-10">
          <div class="ar-3-2 widget-1-wrapper">
            <!-- START WIDGET -->
            <image-widget></image-widget>
            <!-- END WIDGET -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 m-b-10">
          <div class="ar-2-1">
            <!-- START WIDGET -->
            <graph-tile-widget></graph-tile-widget>
            <!-- END WIDGET -->
          </div>
        </div>
        <div class="col-sm-6 m-b-10">
          <div class="ar-2-1">
            <!-- START WIDGET -->
            <bar-tile-widget></bar-tile-widget>
            <!-- END WIDGET -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xlg-4">
      <div class="row">
        <div class="col-sm-6 m-b-10">
          <div class="ar-1-1">
            <!-- START WIDGET -->
            <image-widget-basic></image-widget-basic>
            <!-- END WIDGET -->
          </div>
        </div>
        <div class="col-sm-6 m-b-10">
          <div class="ar-1-1">
            <!-- START WIDGET -->
            <plain-live-widget></plain-live-widget>
            <!-- END WIDGET -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 m-b-10">
          <div class="ar-1-1">
            <!-- START WIDGET -->
            <plain-widget></plain-widget>
            <!-- END WIDGET -->
          </div>
        </div>
        <div class="col-sm-6 m-b-10">
          <div class="ar-1-1">
            <!-- START WIDGET -->
            <graph-live-widget></graph-live-widget>
            <!-- END WIDGET -->
          </div>
        </div>
      </div>
    </div>
    <!-- Filler -->
    <div class="visible-xlg col-xlg-3">
      <div class="ar-2-3">
        <!-- START WIDGET -->
        <table-widget></table-widget>
        <!-- END WIDGET -->
      </div>
    </div>
  </div>
  <!-- END ROW -->
  <div class="row">
    <div class="col-lg-4 col-xl-3 col-xlg-2 ">
      <div class="row">
        <div class="col-md-12 m-b-10">
          <!-- START WIDGET -->
          <graph-tile-flat-widget></graph-tile-flat-widget>
          <!-- END WIDGET -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 m-b-10">
          <!-- START WIDGET -->
          <progress-tile-flat-widget></progress-tile-flat-widget>
          <!-- END WIDGET -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 m-b-10">
          <!-- START WIDGET -->
          <stat-tile-widget></stat-tile-widget>
          <!-- END WIDGET -->
        </div>
      </div>
    </div>
    <div class="col-lg-8 col-xl-5 col-xlg-6 m-b-10">
      <div class="row full-height">
        <div class="col-md-12">
          <!-- START WIDGET -->
          <graph-widget></graph-widget>
          <!-- END WIDGET -->
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-4 m-b-10 hidden-xlg">
      <!-- START WIDGET -->
      <table-basic-widget></table-basic-widget>
      <!-- END WIDGET -->
    </div>
    <div class="col-lg-6 visible-md visible-xlg col-xlg-4 m-b-10">
      <!-- START WIDGET -->
      <stacked-bar-widget></stacked-bar-widget>
      <!-- END WIDGET -->
    </div>
  </div>
  <div class="row">
    <div class="col-lg-8 m-b-10">
      <!-- START WIDGET -->
      <map-widget></map-widget>
      <!-- END WIDGET -->
    </div>
    <div class="col-lg-4 m-b-10">
      <!-- START WIDGET -->
      <realtime-widget></realtime-widget>
      <!-- END WIDGET -->
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 visible-lg hidden-xlg sm-m-b-10 md-m-b-10">
      <!-- START WIDGET -->
      <stacked-bar-widget></stacked-bar-widget>
      <!-- END WIDGET -->
    </div>
    <div class="col-md-4 col-lg-3 col-xlg-3 m-b-10">
      <!-- START WIDGET -->
      <graph-options-widget></graph-options-widget>
      <!-- END WIDGET -->
    </div>
    <div class="col-xlg-2 visible-xlg ">
      <!-- START WIDGET -->
      <social-image-tile-widget></social-image-tile-widget>
      <!-- END WIDGET -->
    </div>
    <div class="col-xlg-2 visible-xlg ">
      <div class="row">
        <div class="col-xlg-12">
          <!-- START WIDGET -->
          <social-post-tile-widget></social-post-tile-widget>
          <!-- END WIDGET -->
        </div>
      </div>
    </div>
    <div class="col-md-8 col-lg-5 col-xlg-5">
      <!-- START WIDGET -->
      <weather-widget></weather-widget>
      <!-- END WIDGET -->
    </div>
  </div>
</div>
<!-- END CONTAINER FLUID -->