<div class="card social-card share {{additionalClasses}}" style="margin-right: 5px;">
  <!-- START ITEM -->
  <div class="circle text-success" data-toggle="tooltip" title="Label" data-container="body"></div>
  <div class="card-content flex-1" data-pages-bg-image style="min-height: 300px;"
    [ngStyle]="{ 'background-image': 'url(' + coverUrl + ')' }">
    <ul class="buttons ">
      <li>
        <a href="#"> <i class="pg-icon">expand</i> </a>
      </li>
      <li>
        <a href="#"><i class="pg-icon">heart</i> </a>
      </li>
    </ul>
  </div>
  <div class="card-description">
    <p><b>{{ name }}</b></p>    
    <p class="hint-text small-text" style="margin-top: -5px;">{{ author }}</p>
    <ng-template [ngTemplateOutlet]="CustomBody"></ng-template>
  </div>
  <div class="card-footer clearfix">
    <!-- <div class="time">{{ timestamp }}</div> -->
    <!-- <ul class="reactions">
      <li>
        <a href="javascript:void(0)" class="d-flex align-items-center">{{ comments }}
          <i class="pg-icon">comment</i>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" class="d-flex align-items-center">{{ likes }}
          <i class="pg-icon">heart</i>
        </a>
      </li>
    </ul> -->
  </div>
  <div class="card-header clearfix last">
    <div class="d-flex small-text hint-text align-items-center">
      {{ timestamp }}
    </div>
    <!-- <div class="user-pic">
      <ng-template [ngTemplateOutlet]="AuthorAvatar"></ng-template>
    </div>
    <h5>{{ author }}</h5>
    <h6 class="d-flex align-items-center">{{ activity }}</h6> -->
  </div>
  <!-- END ITEM -->
</div>