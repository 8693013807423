import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstSentence'
})
export class FirstSentencePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) { return ''; }
    return value.split('. ')[0];
  }

}