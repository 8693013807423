<div class="error-page">
  <div
    class="
      d-flex
      justify-content-center
      full-height full-width
      align-items-center
    "
  >
    <div class="error-container text-center">
      <img
        alt=""
        pgRetina
        src1x="assets/img/demo/pages_icon.png"
        src2x="assets/img/demo/pages_icon_2x.png"
        height="60"
        src="assets/img/demo/pages_icon.png"
        width="60"
      />
      <h2 class="semi-bold">Pages Demo Project</h2>
      <p class="p-b-10">You need to select which layout to load</p>
      <div class="error-container-innner text-center m-b-20">
        <a
          class="d-block m-b-5"
          href="http://localhost:4200/condensed/dashboard"
          >Condensed Layout</a
        >
        <a class="d-block m-b-5" href="http://localhost:4200/casual/dashboard"
          >Casual Layout</a
        >
        <a
          class="d-block m-b-5"
          href="http://localhost:4200/executive/dashboard"
          >Executive Layout</a
        >
        <a
          class="d-block m-b-5"
          href="http://localhost:4200/simplywhite/dashboard"
          >Simply White Layout</a
        >
        <a
          class="d-block m-b-5"
          href="http://localhost:4200/corporate/dashboard"
          >Coporate Layout</a
        >
      </div>
      Use our
      <a
        href="https://docs.pages.revox.io/v/angular/#getting-started"
        target="_blank"
        >documentation</a
      >
      to find the correct routes
    </div>
  </div>
</div>
