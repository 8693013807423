<div class="overlay" [class.hide]=!isVisible [@fadeAnimation]="isVisible">
	<!-- BEGIN Overlay Content !-->

	<div class="overlay-content has-results m-t-20">
		<div class="container-fluid">
			<pg-container>
				<div class="row">
					<div class="col-md-2"></div>

					<div class="col-md-8">
						<input autocomplete="off" #searchField (keyup)="searchKeyPress($event)"
							class="no-border overlay-search bg-transparent" [(ngModel)]="value"
							placeholder="search in posts and pages" spellcheck="false">

						<!-- <button aria-label="" type="button" class="btn btn-success" (click)="searchKeyPress($event)">
							<div class="p-t-5 p-b-5">
								<i class="pg-icon">search</i>
								<div class="fs-11 font-montserrat text-uppercase">Search</div>
							</div>
						</button> -->

						<a class="close-icon-light btn-link btn-rounded  overlay-close text-black"
							href="javascript:void(0)" (click)="close($event)"><i class="pg-icon">close</i></a>

						<div class="d-flex align-items-center">
							<div class="form-check right m-b-0 d-flex" *ngIf="posts.length > 0 || isSearched">
								<i class="pg-icon m-r-5">search</i> {{posts.length}} item(s)
							</div>
							<div class="form-check right m-b-0 d-flex" *ngIf="posts.length == 0 && !isSearched">
								<!-- press enter or click button to search -->
								type something to search
							</div>
						</div>
					</div>

					<div class="col-md-2"></div>
				</div>
			</pg-container>
		</div>
		<!-- BEGIN Overlay Search Results, This part is for demo purpose, you can add anything you like !-->

		<div class="container-fluid">
			<!-- <span><strong>suggestions :</strong></span> {{value}}<br> -->

			<pg-container>

				<div class="row">
					<div class="col-md-2"></div>

					<div class="col-md-8">
						<div class="b-b b-grey" *ngFor="let post of posts">
							<h3>
								<!-- <a class="btn-link"
									(click)="redirectToSinglePost(post.permalink, $event)"><b>{{post.title}}</b></a> -->

								<a class="btn-link" [href]="post.isPage == true ? post.permalink : '/blog/' + post.permalink"><b>{{post.title}}</b></a>
							</h3>
							<p>{{post.summary}}</p>
							<div class="d-flex justify-content-between m-b-5">
								<p class="label small-text hint-text">{{post.language | uppercase}}</p>
								<p class="small-text hint-text">{{ post.publishDate | date:'mediumDate' }}</p>
							</div>
						</div>
					</div>

					<div class="col-md-2"></div>
				</div>



			</pg-container>
		</div>
		<!-- END Overlay Search Results !-->
	</div>
	<!-- END Overlay Content !-->
</div>
<!-- END OVERLAY -->